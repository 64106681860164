<template lang="pug">
div
    v-btn.mt-8( fab text small 
        @click="searchPanelOpen=true"
        absolute left top) 
        v-badge( v-if="$store.getters['search/countCondition']('mallshop')>0"
            bordered  offset-x="3" offset-y="15" color="secondary" overlap bottom 
            :content="$store.getters['search/countCondition']('mallshop')")
            v-icon mdi-magnify 
        v-icon( v-else) mdi-magnify 
    v-dialog(v-model="searchPanelOpen" fullscreen hide-overlay transition="dialog-top-transition")
        v-card( tile)
            v-toolbar(dark tile color="primary")
        
                v-btn(icon dark @click="searchPanelOpen = false")
                    v-icon mdi-close
                v-toolbar-title 搜尋條件
                v-spacer
                v-toolbar-items
                    v-btn( dark text @click="onClear") 清除
                  
            v-form( ref="form1")
                v-container
                    v-select.mt-4(
                        :items="shoppingmalls"
                        v-model="query.shoppingmall_id"
                        item-text="name"
                        item-value="shoppingmall_id"
                        label="請選擇百貨商店"
                        dense outlined clearable)
                    v-select.mt-2(
                        :items="$store.state.options.cities"
                        v-model="query.city"
                        label="縣市"
                        dense outlined clearable)
                    v-row
                        v-col( cols="8")
                            v-select.mt-4(
                                :items="departments"
                                v-model="query.department_name"
                                item-text="department_name"
                                item-value="department_name"
                                label="選擇分館"
                                dense outlined clearable)
                        v-col( cols="4")
                            v-text-field(
                                v-model="query.floor"
                                label="樓層"
                                required clearable)
                    p.d-flex.justify-start.subtitle-2.mb-1 是否為樣本店
                    v-btn-toggle( tile dense
                        color="secondary" class="d-flex justify-start mb-6"
                        v-model="query.is_sample") 
                        v-row
                            v-col(cols="4")
                                v-btn( :value="1" block) 是
                            v-col( cols="4")
                                v-btn( :value="0" block) 否
                    v-checkbox( v-model="query.is_todo"
                        label="只顯示本期尚未完成訪查的資料"
                        color="secondary"
                        :true-value="1"
                        :false-value="0"
                        :value="0"
                        hide-details)
                    v-btn.mt-4( block color="secondary" @click="onComplexSearch" :loading="is_loading") 確定
                
</template>
<style scoped>

  .v-item--active{
      color:inherit !important;
  }
  
</style>
<script>

export default {
    name: 'SearchMallshop',
    props:["search_open"],
    data: () => ({
        is_loading:false,
        searchPanelOpen:false,
        shoppingmalls:[],
        departments:[],
        query:{
            shoppingmall_id:null,
            city:null,
            departments:null,
            floor:null,
            is_sample:null,
            is_todo:null
        }
    }),
    mounted(){
        this.$store.dispatch("http/get",{
          api:`shoppingmalls`
        }).then((data)=>{
            this.shoppingmalls = data.result
        });
        if(this.$store.state.search.mallshop!=null){
            this.query = Object.assign(this.query, this.$store.state.search.mallshop);

        }
    },
    watch:{
        query:{
            handler(newVal,oldVal){
            }
        },
        search_open:{
            handler(newVal,oldVal){
                if(newVal!=null||newVal!=undefined){
                    this.toggle=newVal
                }
            },
            immediate:true
        },
        "query.shoppingmall_id":{
            handler(newValue,oldValue){
                if(newValue!=null&&newValue!=""){
                    this.$store.dispatch("http/get",{
                        api:`shoppingmalls/${newValue}/departments`
                    }).then((data)=>{
                        this.departments = data.result
                    });
                }else{
                    this.departments = []
                }
                
            }
        },
    },
    methods:{
        checkQuery(obj){
            let params = {}
            for(let key in obj){
                let val = obj[key]
                let valType = typeof(val)
                switch (valType) {
                    case 'string':
                        if(val.length>0) params[key] = val
                        break;
                    case 'object':
                        if(val&&Object.keys(val).length>0) params[key] = val
                        break;
                    case 'undefined':
                        break;
                    default:
                        params[key] = val
                        break;
                }

            }
            return Object.keys(params).length==0?false:true
        },
        onComplexSearch(){
            if(this.checkQuery(this.query)){
                console.log("onComplexSearch")
                this.is_loading=true
                this.$store.dispatch("http/post",{
                    api:"mallshops/search",
                    json:this.query
                })
                .then((data)=>{
                        console.log("onComplexSearch data",data)

                    if(data.status){
                        setTimeout(()=>{
                            if(data.result.length>0){
                                this.$emit('emitSearch',data.result);
                                this.$store.commit('search/updateQuery',{type:"mallshop",query:{...this.query}});

                                this.searchPanelOpen=false
                            }else{
                                this.$store.commit('snackBar',{show:true,message:"找不到相符結果",icon:"error",color:""});
                            }
                            this.is_loading=false

                        },1000);
                        
                    }else if(data.code === 0){
                        this.is_loading=false
                        this.$store.commit('snackBar',{show:true,message:"找不到相符結果",icon:"error",color:""});

                    }
                });
            }else{
                this.$emit('emitCleanSearch');

            }
             
        },
        onClear(){
            this.$refs.form1.reset()
            this.query.is_sample = null
            this.$store.commit('search/clearQuery',{type:"mallshop"});
            this.$emit('emitCleanSearch');
        },
        
    }
}
</script>
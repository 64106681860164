<template lang="pug">
v-card( tile flat)
    v-toolbar(class="mb-4" color="secondary" dark)
        v-toolbar-title 選擇品牌
        v-spacer
        v-btn(text  @click="onSubmit") 確定
    v-main(v-if="selected_products.length>0")
        v-container
            v-row
                v-col( cols="12" class="pt-0 d-flex justify-center px-0 pb-0")
                    v-slide-group(class="brand-selector"
                        v-model="current_product_id" 
                        center-active
                        show-arrows mandatory
                    )
                        v-slide-item( v-for="product in selected_products"
                            :key="'p-'+product.product_id"
                            v-slot="{ active, toggle }"
                            :value="product.product_id")
                            div.text-center
                                v-btn.mb-2(icon small @click="deleteProduct(product.product_id)") 
                                    v-icon mdi-close
                                v-card( v-if="selected_products.length>0"
                                    :color="active ? 'primary' : 'grey lighten-5'"
                                    class="ma-1"
                                    height="100"
                                    width="100"
                                    @click="toggle"
                                )  
                                    
                                    v-row(class="fill-height"
                                        align="center"
                                        justify="center"
                                    )
                                        v-col(class="px-7")
                                            
                                            span {{product.product_id}}
                                            br
                                            span {{selected_brands[product.product_id]?'('+selected_brands[product.product_id].length+')':''}}
                                    
                    
            v-text-field.mt-4( clearable
                v-model="query"
                label="搜尋..."
                prepend-icon="mdi-magnify"
            )
            v-row.text-center
                v-col.pb-0
                    v-btn(outlined width="200" color="secondary"  
                        @click="additional_open=true"
                    ) 自訂品牌({{current_additional_brand_count}})項
            v-row( class="mt-2")
                v-col
                    v-progress-circular( v-show="show_progress" indeterminate color="secondary")
            v-row
                v-col(class="px-0")
                    v-expand-transition(v-if="product_brands.length>0")
                        v-row
                            v-col
                                v-sheet( tile flat :height="w_heigth" class="overflow-y-auto")
                                    v-list-item-group(multiple v-model="selected_brands[current_product_id]")
                                        template(v-for="(brand, i) in current_brand_list")
                                            v-list-item(v-show="onSearch(brand.brand_name,query)"
                                                :key="`item-${brand.product_id}-${i}`"
                                                :value="brand.product_id+'-'+brand.brand_id"
                                                active-class="yellow lighten-5 third--text"
                                            )
                                                template( v-slot:default="{ active }")
                                                    v-list-item-content
                                                        v-list-item-title(v-text="brand.brand_name")
                                                    v-list-item-action
                                                        v-checkbox(:input-value="active" color="secondary")
                                                
        v-bottom-sheet(
            v-model="additional_open"
            persistent 
        )
            v-card(tile flat height="400")
                v-toolbar(class="mb-4" color="secondary" dark)
                    v-toolbar-title 自訂品牌
                    v-spacer
                    v-btn( text @click="onAdditionalBrandSave") 完成

                v-container(class="text-center")
                    v-combobox(ref="combo1"
                        v-model="current_additional_brand"
                        label="在此新增吧"
                        multiple chips dense outlined
                        deletable-chips
                    )
</template>
<style>

.brand-selector .v-slide-group__content{
    overflow-x:scroll !important;
    overflow-y:hidden !important;
    -webkit-overflow-scrolling: touch !important;
}

</style>
<script type="text/javascript">
export default {
    props:["selected_shop_category_id","selected_products","brands","additional_brand"],
  
    data: () => ({
        query:"",
        additional_open:false,
        current_product_id:"",
        selected_brands:{},
        product_brands:[],
        current_additional_brand:[],
        selected_additional_brand:[],
        valid: true,
        show_progress:true,
        errMsg:'',
    }),
    mounted(){
    },
    watch:{
        
        "selected_products":{
            handler(newValue,oldValue){
                if(newValue && newValue.length>0){
                    this.current_product_id=newValue[0].product_id
                    //refresh brands list
                    this.$store.dispatch("http/get",{
                        api:`categories/${this.selected_shop_category_id}/products/brands`,
                    }).then((data)=>{

                        this.show_progress=false
                        this.product_brands=data.result
                        //checkbox on if db exist
                        let new_brands = {...this.selected_brands}
                        for(let idx in this.brands){
                            let b = this.brands[idx]
                            if(!new_brands[b.product_id]){
                                new_brands[b.product_id]=[]
                            }
                            let b_str = b.product_id+'-'+b.brand_id
                            if(!new_brands[b.product_id].find(x => x == b_str )){
                                new_brands[b.product_id].push(b_str)

                            }   

                        }
                        this.selected_brands = new_brands
                        //remove unselect product's brand
                        for(let p_id in this.selected_brands){
                            if(!this.selected_products.find(x => x.product_id==p_id) ){
                                delete this.selected_brands[p_id]
                            }
                        }
                        //add empty if not exist
                        for(let i in newValue){
                            let product = newValue[i]
                            if (!(product.product_id in this.selected_brands)){
                                this.$set(this.selected_brands, product.product_id,[]) 
                            }
                        }
                        
                        this.dialogHandle(false)
                    });
                    
                }else{
                    this.selected_brands={}
                    this.dialogHandle(true)
                }
               
            },
            deep:true,
            immediate:true
        },
        "additional_brand":{
            handler(newValue,oldValue){
                if(newValue){
                    this.selected_additional_brand = [...this.additional_brand]

                }
            },
            immediate:true
        },
       "current_product_id":{
            handler(newValue,oldValue){
                let brands = this.selected_additional_brand.filter(x=>x.product_id==newValue)
                let result = []
                if(brands){
                    result = brands.map((b)=>{
                        return b.brand_name
                    })
                    
                }
                this.current_additional_brand = result

            },
            immediate:true

       }
    },
    computed:{
        current_additional_brand_count(){
           let data =  this.selected_additional_brand.filter(x=>x.product_id==this.current_product_id)
           return data?data.length:0
        },
        current_brand_list(){
            let item_list = this.product_brands.find(x=>x.product_id==this.current_product_id).brands
            return item_list
        },

          w_heigth(){
              return (window.innerHeight*0.9)-170-120;
          }
      },
    methods: {
        deleteProduct(product_id){
            this.$emit("onDeleteProduct",product_id)
        },
        onAdditionalBrandSave(){
            this.$refs.combo1.blur()
            this.$nextTick(() => {
                let brands = this.current_additional_brand.map((name)=>{
                    return {
                        brand_name:name,
                        product_id:this.current_product_id,
                        shop_category_id:this.selected_shop_category_id
                    }
                })
                //clean current product's brands
                this.selected_additional_brand = this.selected_additional_brand.filter(( obj ) =>{
                    return obj.product_id !== this.current_product_id;
                });
                //add back
                this.selected_additional_brand = this.selected_additional_brand.concat(brands)
                this.additional_open=false
            });
        },
        onSearch(target,query){
            if(query&&query!=""){
                return target.toLowerCase().indexOf(query.toLowerCase())>=0?true:false
            }else{
                return true
            }
        },
        
        transformData(){
            let result1 = []

            for(let p_id in this.selected_brands){
                let brands = this.selected_brands[p_id]
                for(let idx in brands){
                    let b = brands[idx].split('-')[1]
                    result1.push({
                        brand_id:parseInt(b),
                        product_id:p_id,
                        shop_category_id:this.selected_shop_category_id
                    })
                }
                
            }
            
            return {brands:result1,additional_brand:this.selected_additional_brand}
        },
        dialogHandle(is_close){
            this.$emit('dialogHandle',this.transformData(),is_close);

        },
        onSubmit() {

            for(let k in this.selected_brands){
                let additional_count = this.selected_additional_brand.filter(x=>x.product_id==k).length
                if(this.selected_brands[k].length+additional_count==0){
                    this.$store.commit('snackBar',{show:true,message:"還有產品尚未選擇品牌！",icon:"error",color:""});
                    return false
                }
            }
            this.dialogHandle(true)
        },
        clear() {
            this.$refs.form.reset()
        }
    }
}
</script>
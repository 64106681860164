<template lang="pug">
v-app  
    SearchMallshop(
        v-click-outside="onClickOutside"
        :search_open="ui.search_open" 
        @emitHandle="onSearchToggle" 
        @emitSearch="onSearchResult"
        @emitCleanSearch="onCleanSearch")
    //- v-speed-dial(v-if="$store.state.ui.uc_mode==false" bottom left absolute small
    //-     v-model="sort.is_open"  class="mb-16"
    //-     direction="top")
      
    //-     template( v-slot:activator)
    //-         v-btn( small fab v-model="sort.is_open")
    //-             v-icon( v-if="sort.is_open" color="accent") mdi-close
    //-             v-icon( v-else color="accent") mdi-filter-menu
          
    //-     v-btn( fab small @click="sort.by='updated_time'" :color="sort.by=='updated_time'?'primary':''") 更新#[br]日期
    //-     v-btn( fab small @click="sort.by='is_done'" :color="sort.by=='is_done'?'primary':''") 依照#[br]顏色
        
    v-card( color="grey lighten-5" flat)
        v-container.mt-4
            v-row( dense)
                v-virtual-scroll( class="pb-16 mt-8" bench="4"
                  :items="mallshops"
                  :item-height="200"
                  min-height="500"
                  :max-height="w_heigth")
                    template( v-slot:default="{index, item }")
                        v-col( cols="12")
                            v-card.shop-card(class="" elevation="10" :style="{ 'border-left-color':getColor(item.year)+' !important'}")
                                div.ml-4.mr-2.pt-2.d-flex.justify-start
                                    span 編輯者 {{item.updated_name}} 
                                    v-spacer
                                    v-badge(v-if="item.is_sample"
                                        bordered offset-x="60" offset-y="40"
                                        color="info"
                                        overlap bottom
                                        :content="'樣本店'")
                                    v-switch.mt-0( dense :loading="is_loading"
                                        @click.native.prevent.stop.capture="onSwitchClick(item,$event)"
                                        
                                        v-model="item.is_open"  
                                        :true-value="1" 
                                        :false-value="0" )
                                
                                v-card-title.subtitle-1.pt-0 {{item.shoppingmall_name}}
                                v-card-subtitle.pb-0.d-flex.justify-space-between  
                                    span {{item.department_name}} 
                                    span 樓層 {{item.floor}}
                                v-card-subtitle.py-0.d-flex.justify-space-between 
                                    span ID：{{item.mallshop_code}} 
                                    span.ml-6 櫃位：{{item.desk_name}}
                                v-card-actions
                                    v-row
                                        v-col
                                            small.ml-2 {{item.updated_time||item.created_time | moment(  "YYYY年MM月DD日")}}

                                    v-spacer
                                    v-btn( fab icon x-small @click="$store.dispatch('ui/copyMallshop',item)" ) 
                                        v-icon mdi-content-copy
                                    v-btn( fab icon x-small @click="$router.push({path:'/mallshopedit/'+item.mallshop_id});")
                                        v-icon mdi-square-edit-outline

                            v-card( v-if="index==mallshops.length-1" v-intersect.once="infiniteHandler")
                            v-sheet(v-if="index==mallshops.length-1" height="160" color="transparent")

                 
</template>
<style scoped>
.shop-card{
    border-left:solid !important;
    border-left-width:8px !important;
}
.v-btn a{
  text-decoration: none !important;
}
</style>
<script>
import SearchMallshop from '@/components/common/SearchMallshop'
  export default {
    components: {
        SearchMallshop
    },
    mounted(){
        this.getShops()
    },
    data: () => ({
        page:1,
        is_loading:false,
        sort:{
            is_open:false,
            by:'updated_time'
        },
        ui:{
            search_open: false,
            layout_toggle:0,
        }, 
        mallshops:[]
    }),
    computed:{
        w_heigth:()=>{
            return window.innerHeight-65;
        },
        sorted_shops(){
            if(this.sort.by=='updated_time'){
                return this.mallshops.map(x=>x).sort((a,b) => {
                return this.$moment(b.updated_time).valueOf()-this.$moment(a.updated_time).valueOf()}  )
            }else{
                return this.shops.map(x=>x).sort((a,b) => a.full_address - b.full_address )

            }
        }
    },
    methods:{
        infiniteHandler(entries, observer, isIntersecting){
            if(isIntersecting){
                this.nextPage()
            }
        },
        nextPage(){
            this.page+=1
            this.getShops()
        },
        getShops(){
            
            if(this.$store.state.search.mallshop!=null){
                this.$store.dispatch("search/getMallshopSearchResult",{page:this.page})
                .then((data)=>{
                    console.log(data)
                    this.mallshops=[...this.mallshops,...data]
                }); 
            }else{
                this.$store.dispatch("http/get",{
                    api:`mallshops`,
                    json:{page:this.page}
                }).then((data)=>{
                    console.log(data)
                    this.mallshops = [...this.mallshops,...data.result]
                });
            }
        },
        onSearchResult(data){
            this.page=1
            this.mallshops=data
        },
        onClickOutside(){
            if(this.ui.search_open){
                this.ui.search_open=false
            }
        },
        onSearchToggle(flag){
            this.ui.search_open=flag
        },
        onCleanSearch(){
            this.page=1
            this.getShops()
        },
        getColor(year){
          return year?this.$store.state.ui.color.shop_state["done"]:this.$store.state.ui.color.shop_state["pending"]
        },
       
        onSwitchClick(item,e){
            this.$store.commit('dialogBox',{dialog:true,option:{
                title:item.is_open?"永久歇業":"開啟營業",
                message:item.is_open?`此動作將會強制「完成訪查」，你確定<strong>ID：${item.mallshop_id}</strong>永久歇業?`:`此動作將會取消「完成訪查」的紀錄，你確定<strong>ID：${item.mallshop_id}</strong>要開啟營業?`,
                btnCancelShow:true,
                icon:'warning',
                btnConfirmText:'確定執行',
                btnConfirmEvent:()=>{
                    item.is_open = item.is_open?0:1
                    this.is_loading=true
                    this.$store.dispatch("http/put",{
                        api:`mallshops/open/${item.mallshop_id}/${item.is_open}`
                    }).then((data)=>{
                        this.is_loading=false
                        if(data.status){
                            if(item.is_open){
                                this.$store.commit('snackBar',{show:true,message:"已將店家狀態改為「正常營業」",icon:"success",color:""});
                            }else{
                                this.$store.commit('snackBar',{show:true,message:"已將店家狀態改為「永久停業」",icon:"success",color:""});
                            }
                        }else{
                                this.$store.commit('snackBar',{show:true,message:"更新失敗！",icon:"error",color:""});

                        }
                    })
                }
            }});
        
        
        }
    }
  }
</script>
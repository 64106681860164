<template>
<v-app> 
<v-main>
    <v-toolbar color="accent" class="white--text">
      <v-toolbar-title>我的訪查進度</v-toolbar-title>
    </v-toolbar> 
      <v-progress-linear v-show="is_loading" class="mt-1"
          indeterminate
          color="primary"
      ></v-progress-linear>
    <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            縣市
          </th>
          <th class="text-left">
            地區
          </th>
          <th class="text-left pa-0" width="40">
            訪查數
          </th>
          <th class="text-left pa-0" width="40">
            店休數
          </th>
          <th class="text-left" width="90">
            進度
          </th>
        </tr>
      </thead>
      <tbody class="my-tbody">
        <tr
          v-for="(item,idx) in progress"
          :key="'i-'+idx" @click="item.percentage==100||onRowClick(item)"
        >
            <td class="pr-0">{{ item.city }}</td>
            <td class="pr-0">{{ item.district }}</td>
            <td>{{item.finished}}/{{ item.total }}</td>
            <td>{{item.shop_close_count}}</td>
            <td>
                <v-progress-linear :color="getColor(item.percentage)"
                    v-model="item.percentage"
                    height="20"
                >
                    <b class="blue-grey--text"><small>{{ Math.ceil(item.percentage) }}%</small></b>
                </v-progress-linear>
            </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</v-main>
</v-app>
</template>
 
<style scoped>
.my-tbody td{
/* padding: 0px !important; */
}
</style>
<script>

export default {
  components:{},
  props:[],
  inject:['reload'],
  data () {
    return {
        progress: [],
        is_loading:true
        
    }
  },
  computed:{
     
    },
  mounted(){
      this.$store.dispatch("http/get",{api:"shops/myprogress"})
      .then((data)=>{
        this.progress=data.result
        this.is_loading=false
      });  
  },
   methods:{
       getColor(number){
           if(number<25) return 'error'
           else if (number<75) return 'warning'
           else if(number<=99) return 'info'
           else if(number==100) return 'success' 
           else return 'error'
       },
      onClose(){
          this.$emit("onClose")

      },
      onRowClick(item){
        this.$store.commit('search/clearQuery',{type:"shop"})
        this.$store.commit('search/updateQuery',{
          type:"shop",
          query:{
              city:item.city,
              district:item.district,
              is_owner:1,
              is_todo:1
        }});
        if(this.$router.currentRoute.path.indexOf('map')>=0){
          this.reload()
        }else{
          this.$router.push({path:'/map'});
        }
       
      }
   }
}
</script>
 
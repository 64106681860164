var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[(_vm.selected_products.length>0)?_c('div',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"py-0 px-0 d-flex justify-center",attrs:{"cols":"12"}},[_c('v-slide-group',{staticClass:"brand-selector",attrs:{"center-active":"","show-arrows":"","mandatory":""},model:{value:(_vm.current_product_id),callback:function ($$v) {_vm.current_product_id=$$v},expression:"current_product_id"}},_vm._l((_vm.selected_products),function(product){return _c('v-slide-item',{key:'p-'+product,attrs:{"value":product},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [(_vm.selected_products.length>0)?_c('v-card',{staticClass:"ma-1",attrs:{"color":active ? 'primary' : 'grey lighten-5',"height":"100","width":"100"},on:{"click":toggle}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"px-7"},[_c('span',[_vm._v(_vm._s(product))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.selected_brands[product]?'('+_vm.selected_brands[product].length+')':''))])])],1)],1):_vm._e()]}}],null,true)})}),1)],1)],1),_c('v-row',[_c('v-col',[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_loading),expression:"is_loading"}],staticClass:"mt-0",attrs:{"indeterminate":"","color":"primary"}}),_c('v-autocomplete',{ref:"combobox_brand",attrs:{"autofocus":"","solo":"","clearable":"","multiple":"","small-chips":"","items":_vm.sorted_brand_list,"item-text":"brand_name","item-color":"accent","filter":_vm.filter,"search-input":_vm.search,"label":"選擇品牌","hide-no-data":!_vm.search,"rules":_vm.$store.getters['validation/inputRules'](['required'])},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"small":"","label":"","color":"accent","close":"","input-value":selected},on:{"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_c('span',{on:{"click":function($event){return _vm.editSingleItem(item)}}},[_vm._v(_vm._s(item.brand_name)+" ("+_vm._s(_vm.edit_brand_list[item.product_id+'-'+item.brand_id]||0)+")")])]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',{class:{'selected-item':attrs.inputValue},on:{"click":function($event){return _vm.edit(item,on)}}},[_c('v-chip',{attrs:{"color":attrs.inputValue?'accent':'info',"dark":"","label":"","small":""}},[_vm._v(_vm._s(item.brand_name)+" ("+_vm._s(_vm.edit_brand_list[item.product_id+'-'+item.brand_id]||0)+")")]),(_vm.editing&&_vm.editing.brand_id === item.brand_id)?_c('v-text-field',{ref:"quantity",attrs:{"placeholder":"數量","type":"number","autofocus":"","solo":"","flat":"","hide-details":"","background-color":"transparent"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.edit(item,on)}},model:{value:(_vm.editing.quantity),callback:function ($$v) {_vm.$set(_vm.editing, "quantity", $$v)},expression:"editing.quantity"}}):_vm._e(),_c('v-spacer'),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s((_vm.editing&&_vm.editing.brand_id !== item.brand_id)||!_vm.editing ? 'mdi-pencil' : 'mdi-check'))])],1)],1)],1)]}}],null,false,1592639505),model:{value:(_vm.selected_brands[_vm.current_product_id]),callback:function ($$v) {_vm.$set(_vm.selected_brands, _vm.current_product_id, $$v)},expression:"selected_brands[current_product_id]"}})],1)],1),_c('v-btn',{attrs:{"width":"200","color":"secondary"},on:{"click":_vm.onSubmit}},[_vm._v("確定")])],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="pug">
v-main
    div( v-if="selected_products.length>0") 
        v-container
            v-row
                v-col.py-0.px-0.d-flex.justify-center( cols="12")
                    v-slide-group.brand-selector(
                        v-model="current_product_id" 
                        center-active
                        show-arrows mandatory)
                        v-slide-item( 
                            v-for="product in selected_products"
                            :key="'p-'+product"
                            v-slot="{ active, toggle }"
                            :value="product")
                            v-card.ma-1(v-if="selected_products.length>0"
                                :color="active ? 'primary' : 'grey lighten-5'"
                                height="100"
                                width="100"
                                @click="toggle")
                                     
                                v-row.fill-height(align="center" justify="center")
                                    v-col.px-7
                                        span {{product}}
                                        br
                                        span {{selected_brands[product]?'('+selected_brands[product].length+')':''}}
            v-row
                v-col
                    v-progress-linear( v-show="is_loading" class="mt-0"
                        indeterminate
                        color="primary")
                    v-autocomplete( autofocus ref="combobox_brand"  
                        solo clearable multiple small-chips 
                        v-model="selected_brands[current_product_id]"
                        :items="sorted_brand_list"
                        item-text="brand_name"
                        item-color="accent"
                        :filter="filter"
                        :search-input.sync="search"
                        label="選擇品牌"
                        :hide-no-data="!search"
                        :rules="$store.getters['validation/inputRules'](['required'])")

                        template( v-slot:selection="{ attrs, item, parent, selected }")
                            v-chip( small label
                                v-if="item === Object(item)"
                                v-bind="attrs"
                                color="accent" close
                                @click:close="remove(item)"
                                :input-value="selected" )
                                span(@click="editSingleItem(item)") {{ item.brand_name }} ({{edit_brand_list[item.product_id+'-'+item.brand_id]||0}})
                                //- v-icon(small @click="parent.selectItem(item)") mdi-close
                            
                        template( v-slot:item="{ item ,on,attrs}")
                            v-list-item(@click="edit(item,on)" :class="{'selected-item':attrs.inputValue}")
                                v-chip( :color="attrs.inputValue?'accent':'info'" dark label small) {{ item.brand_name }}  ({{edit_brand_list[item.product_id+'-'+item.brand_id]||0}})
                                
                                v-text-field( ref="quantity"
                                    v-if="editing&&editing.brand_id === item.brand_id"
                                    placeholder="數量"
                                    v-model="editing.quantity" type="number"
                                    autofocus solo flat hide-details
                                    background-color="transparent"
                                    @keyup.enter="edit(item,on)")
                                v-spacer
                                v-list-item-action
                                    v-btn(icon)
                                        v-icon {{ (editing&&editing.brand_id !== item.brand_id)||!editing ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
                            
            v-btn( width="200" color="secondary"  @click="onSubmit") 確定
</template>
<style scoped>
.selected-item{
    background-color: #c4d6eb;
}
.brand-selector .v-slide-group__content{
    overflow-x:scroll !important;
    overflow-y:hidden !important;
    -webkit-overflow-scrolling: touch !important;
}
.keyboard-on > .v-menu__content{
    padding-top:45px !important;
}
</style>
<script type="text/javascript">
export default {
    props:["selected_products","brands"],
  
    data: () => ({
        query:"",
        edit_brand_list:{},
        editing: null,
        search: null,
        is_loading:false,
        current_product_id:"",
        selected_brands:{},
        brand_list:[],
        sorted_brand_list:[],
        valid: true,
        errMsg:'',
        is_keyboard_open:false,
        visualViewport:"",

    }),
    mounted(){
        this.visualViewport=screen.height
        window.visualViewport.onresize = (e) => {
            this.visualViewport=e.currentTarget.height
        }
    },
    watch:{
        edit_brand_list:{
             handler(newVal,oldVal){
                console.log("edit_brand_list",newVal)
            },
            deep:true
        },
        search:{
             handler(newVal,oldVal){
                console.log("search",newVal)
            }
        },
        is_keyboard_open:{
            handler(newVal,oldVal){
                this.$store.commit('ui/setKeyboard',newVal);
            }
        },
        "visualViewport":{
            handler(newVal,oldVal){
                if(this.$refs.combobox_brand.isFocused&&oldVal-newVal>=200){
                    this.is_keyboard_open = true
                }else{
                    this.is_keyboard_open = false
                }
                
            }
        },
        "brands":{
            handler(newValue,oldValue){
                console.log("watch brands 111",newValue,oldValue)

                if(newValue&&newValue!=oldValue){
                    let result =[]
                    for(let idx in newValue){
                        let b = newValue[idx]
                        if(result[b.product_id]){
                            result[b.product_id].push({...b})
                        }else{
                            result[b.product_id]=[]
                            result[b.product_id].push({...b})
                        }
                        this.edit_brand_list[b.product_id+'-'+b.brand_id] = b.quantity
                    }
                    console.log("watch brands2222",newValue,oldValue)
                    
                    this.selected_brands = result
                   
                }
            },
            immediate:true
        },
        "selected_products":{
            handler(newValue,oldValue){
                if(newValue && newValue.length>0){
                    this.current_product_id=newValue[0].product_id
                    //add empty if not exist
                    console.log("watch selected_products 111")

                    for(let i in newValue){
                        let product = newValue[i]
                        if (!(product in this.selected_brands)){
                            console.log("watch selected_products 222")

                            this.$set(this.selected_brands, product,[]) 
                        }
                    }
                    
                }
            },
            deep:true,
            immediate:true
        },
        
       "current_product_id":{
            handler(newValue,oldValue){
                this.is_loading=true
                if(newValue){
                    this.$store.dispatch("http/get",{
                        api:`products/${newValue}`,
                    }).then((data)=>{
                        console.log("get apiiiii",this.selected_brands)
                        this.brand_list=data.result.brands.map((x)=>{
                            let is_selected = this.selected_brands[newValue].find( y =>y.brand_name == x.brand_name &&y.product_id==newValue )
                            x.sort = is_selected?'#0#'+x.brand_name:'#1#'+x.brand_name
                            return x
                        })
                       
                        setTimeout(()=>{
                            this.is_loading=false

                        },1800)
                    });
                }
            },
            immediate:true

       },
       "brand_list":{
            handler(newValue,oldValue){
                console.log("watch brand_list" )
                console.log(" see edit_brand_list" ,this.edit_brand_list)

                let result = newValue.map(x=>x).sort((a,b) => {

                    if (a.sort > b.sort) {
                        return 1;
                    }
                    if (b.sort > a.sort) {
                        return -1;
                    }
                    return 0;
                }) 
                console.log(" see result" ,result)

                this.sorted_brand_list = [...result]
            },
            deep:true
       }
    },
    computed:{
       
          w_heigth(){
              return (window.innerHeight*0.9)-170-120;
          }
      },    
    methods: {
        editSingleItem(item){
            console.log("editSingleItem",item)
            // this.search = item.brand_name
        },
       
        transformData(){
            let result1 = []
            let result2 = []

            for(let p_id in this.selected_brands){
                let brands = this.selected_brands[p_id]
                for(let idx in brands){
                    let b = brands[idx]
                    let q = this.edit_brand_list[b.product_id+'-'+b.brand_id]
                    
                    result1.push({
                        brand_id:b.brand_id,
                        brand_name:b.brand_name,
                        product_id:b.product_id,
                        quantity:q?parseInt(q):0
                    })
                }
                
            }
            return {brands:result1}
        },
        dialogHandle(is_close){
            this.$emit('dialogHandle',this.transformData(),is_close);

        },
        onSubmit() {

            for(let k in this.selected_brands){
                if(this.selected_brands[k].length==0){
                    this.$store.commit('snackBar',{show:true,message:"還有產品尚未選擇品牌！",icon:"error",color:""});
                    return false
                }
            }
            this.dialogHandle(true)
        },
        switchSelection(idx,selected){
            let item = this.brand_list[idx]
            if(selected){
                //排序比較前面
                item.sort = item.sort.replace("#1#","#0#")
            }else{
                item.sort = item.sort.replace("#0#","#1#")
            }
            // this.$set(this.brand_list, idx,item) 
            console.log("switchSelection",item)
            return item
        },
        clear() {
            this.$refs.form.reset()
        },
        remove (item) {
            let idx1 = this.selected_brands[this.current_product_id].findIndex(x=> x.brand_name == item.brand_name)
            if (idx1 >= 0) this.selected_brands[this.current_product_id].splice(idx1, 1)
            let unselected_item = this.brand_list.findIndex(x=> x.brand_name == item.brand_name)
            this.switchSelection(unselected_item,false)

        },
        edit ( item,callback) {
            const setEditingToCurrentItem=()=>{
                this.editing = {
                    brand_id:item.brand_id,
                    brand_name:item.brand_name,
                    product_id:item.product_id,
                    quantity:""
                }
            }
            const updateEditingTarget = (is_same)=>{
                if(is_same){
                    this.editing = null
                }else{
                    setEditingToCurrentItem()
                    if(this.$refs.quantity){
                        this.$refs.quantity.focus()
                    }
                    

                }
            }
            if (!this.editing) {//is empty so change to edit mode
                setEditingToCurrentItem()
            } else {//has data
                //is same item clikced?
                let is_same = this.editing.brand_id==item.brand_id
                
                if(parseInt(this.editing.quantity)>0){//if has entered 
                     this.edit_brand_list[`${this.editing.product_id}-${this.editing.brand_id}`] = this.editing.quantity
                     let is_exist_item = this.selected_brands[item.product_id].find(x=>x.brand_id == this.editing.brand_id)
                     if(is_exist_item){
                        //update to selected
                        console.log("is_exist_item") 
                        this.remove(is_exist_item)    
                    } 
                    //add to selected
                    console.log("add to selected")
                    this.selected_brands[item.product_id].push({
                        brand_id:this.editing.brand_id,
                        brand_name:this.editing.brand_name,
                        product_id:this.editing.product_id
                    })
                     
                    
                    
                    let selected_item = this.brand_list.findIndex(x=> x.brand_name == this.editing.brand_name)
                    this.switchSelection(selected_item,true)
                    updateEditingTarget(is_same)
                }else{//no quantity
                    updateEditingTarget(is_same)
                }
            }
      },
        filter (item, queryText, itemText) {
            if (item.header) return false
            this.is_loading=true
            const hasValue = val => val != null ? val : ''

            const text = hasValue(itemText)
            const query = hasValue(queryText)

            let result = text.toString()
            .toLowerCase()
            .indexOf(query.toString().toLowerCase()) > -1
            this.is_loading=false
            return result
        },
    }
}
</script>
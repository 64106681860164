<template>
<div>
    
    <v-dialog v-model="$store.state.guide.is_show"
    >
    <v-toolbar
          dark tile
          color="primary"
        >
          <v-toolbar-title>說明</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="$store.commit('guide/close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      <v-card tile min-height="200">
        <v-container><p v-html="$store.getters['guide/getContent'](page)"></p></v-container>
        
      </v-card>
    </v-dialog>
</div>
</template>
<script>

export default {
    name: 'GuideDialog',
    props:["page"],
    data: () => ({
        
    }),
    computed:{
       
    },
    mounted(){
        
    },
   
    methods:{
        
    }
}
</script>
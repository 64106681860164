<template lang="pug">
v-app 
    v-card( color="grey lighten-5" flat)
        v-container
            v-progress-linear(v-show="is_loading"
                indeterminate
                color="primary")
            v-row(dense)
                v-virtual-scroll.pb-16.mt-8( bench="4"
                  :items="shops"
                  :item-height="170"
                  min-height="500"
                  :max-height="w_heigth")
                    template( v-slot:default="{ index,item }")
                        v-col( cols="12")
                            MapInfoCard( :info="item" :show_photo="false")
                        v-card( v-if="index==shops.length-1" v-intersect.once="infiniteHandler")
                        v-sheet(v-if="index==shops.length-1" height="160" color="transparent")

</template>
<style scoped>
.shop-card{
    border-left:solid !important;
    border-left-width:8px !important;
}
.v-btn a{
  text-decoration: none !important;
}
</style>
<script>
import MapInfoCard from '@/components/common/MapInfoCard.vue'

  export default {
    components: {MapInfoCard},
    props:["shops"],
    data: () => ({
      is_loading:false
    }),
    computed:{
        w_heigth:()=>{
            return window.innerHeight-65;
        }
    },
    methods:{
      infiniteHandler(entries, observer, isIntersecting){
        if(isIntersecting){
          console.log("infiniteHandler")
          this.$emit("onInfiniteScroll")
        }
      
    },
      
    }
  }
</script>
<template>
<div>
    <v-app-bar 
        :collapse="!toggle"
        absolute flat
        :color="toggle?'white':'transparent'"
        scroll-target="#scrolling-techniques-6"
    >
         <v-badge v-if="$store.getters['search/countCondition']('shop')>0"
            bordered offset-x="15" offset-y="22"
            color="secondary"
            overlap bottom
            :content="$store.getters['search/countCondition']('shop')"
        >
            <v-btn icon @click="onToggle">
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
        </v-badge>
        <v-btn icon @click="onToggle" v-else>
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
        <v-text-field hide-details single-line :loading="is_loading"
            v-show="toggle"
            v-model="query.search_text" clearable
            placeholder="輸入文字搜尋店名、地址"
        >
            <template v-slot:append>
                <v-btn text small class="px-0"
                    :color="query.is_global_search?'accent':'secondary'" 
                    @click="query.is_global_search = !query.is_global_search">
                    {{query.is_global_search?'全域':'條件'}}
                    <v-icon small>mdi-chevron-down</v-icon>
                </v-btn>
            </template>
        </v-text-field>
        <v-btn icon small @click="searchPanelOpen=true" v-if="toggle">
            <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
    </v-app-bar>
    <v-dialog
      v-model="searchPanelOpen"
      fullscreen
      hide-overlay
      transition="dialog-top-transition"
    >
      <v-card tile>
        <v-toolbar
          dark tile
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="searchPanelOpen = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>搜尋條件</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="onClear"
            >
              清除
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
         <v-form ref="form1">
            <v-container>
              
                <v-select class="mt-6" dense outlined
                    v-model="query.shop_category_id"
                    :items="$store.state.options.shop_categories"
                    item-text="name"
                    item-value="shop_category_id"
                    label="商店店型"
                    :rules="$store.getters['validation/inputRules'](['required'])"
                ></v-select>
                <v-select dense outlined
                    v-model="query.mall_id"
                    :items="malls"
                    item-text="name"
                    item-value="mall_id"
                    label="商圈"
                    :rules="$store.getters['validation/inputRules']([])"
                ></v-select>
                <v-row>
                    <v-col class="pb-0" cols="6">
                        <v-select
                            :items="$store.state.options.cities"
                            v-model="query.city"
                            label="縣市"
                            dense outlined
                            :rules="$store.getters['validation/inputRules'](['required'])"
                        ></v-select>
                    </v-col>
                    <v-col class="pb-0" cols="6">
                        <v-select
                            :items="city_list"
                            :rules="$store.getters['validation/inputRules'](['required'])"
                            v-model="query.district"
                            item-text="district"
                            item-value="district"
                            label="鄉鎮市區"
                            dense outlined
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <p class="d-flex justify-start subtitle-2 mb-1">店面形式</p>
                        <v-btn-toggle color="secondary" class="d-flex justify-start"
                            v-model="query.shop_form">
                            <v-btn value="店面">店面</v-btn>
                            <v-btn value="櫃檯">櫃檯</v-btn>
                        </v-btn-toggle>
                    </v-col>
                    <v-col>
                        <p class="d-flex justify-start subtitle-2 mb-1">商店位置</p>
                        <v-btn-toggle color="secondary" class="d-flex justify-start mb-6"
                            v-model="query.shop_position" >
                            <v-btn value="街上">街上</v-btn>
                            <v-btn value="賣場/大樓">賣場/大樓</v-btn>
                            <v-btn value="地下街">地下街</v-btn> 
                        </v-btn-toggle>
                    </v-col>
                </v-row>
                <v-checkbox
                    v-model="query.is_todo"
                    label="只顯示本期尚未完成訪查的資料"
                    color="secondary"
                    :true-value="1"
                    :false-value="0"
                    :value="0"
                    hide-details
                ></v-checkbox>
                <v-checkbox
                    v-model="query.is_owner"
                    label="只顯示我的店家"
                    color="secondary"
                    :true-value="1"
                    :false-value="0"
                    :value="1"
                    hide-details
                ></v-checkbox>
                <v-btn block color="secondary" class="mt-2"
                @click="onSearch('complex')" :loading="is_loading"
                > 確定
                </v-btn>
            </v-container>
        </v-form>
      </v-card>
    </v-dialog>
</div>
</template>
<script>
import { debounce } from "debounce";

export default {
    name: 'Search',
    props:["search_open"],
    data: () => ({
        
        is_loading:false,
        toggle:false,
        searchPanelOpen:false,
        malls:[],
        query:{
            is_global_search:true,
            search_text:"",
            shop_category_id:null,
            mall_id:null,
            city:null,
            district:null,
            shop_form:"",
            shop_position:"",
            is_todo:0,
            is_owner:1
        }
    }),
    computed:{
        city_list(){
            let cities = this.$store.state.options.districts[this.query.city]
            return  cities?cities['districts']:[]
        }
    },
    mounted(){
        this.$store.dispatch("options/getShopCategories")
        this.getMalls()
        if(this.$store.state.search.shop!=null){
            this.query = Object.assign(this.query, this.$store.state.search.shop);

        }
    },
    watch:{
        "query.is_global_search":{
            handler(newVal,oldVal){
                if(newVal != oldVal && this.query.search_text != ""){
                    this.onQuickSearchDebounce()
                }
            }
        },
        "$store.state.search.shop":{
            handler(newVal,oldVal){
                this.query = Object.assign(this.query, this.$store.state.search.shop);

            }
        },
        "query.search_text":{
            handler(newVal,oldVal){
                if(!this.searchPanelOpen && (oldVal||newVal)){
                    this.onQuickSearchDebounce()

                }
            }
        },
        search_open:{
            handler(newVal,oldVal){
                if(newVal!=null||newVal!=undefined){
                    this.toggle=newVal
                }
            },
            immediate:true
        }
    },
    methods:{
        onQuickSearchDebounce: debounce( function() {
            if(this.query.is_global_search){
                this.onSearch('easy')
            }else{
                this.onSearch('complex')
            }
            
        }, 2000),
        getMalls(){
            this.$store.dispatch('http/get',{
                api:`malls`,
                json:{}
            })
            .then((data)=>{
                if(data.status){
                    this.malls = data.result
                }
            })
        },
       
        checkQuery(obj){
            let params = {}
            for(let key in obj){
                let val = obj[key]
                let valType = typeof(val)
                switch (valType) {
                    case 'string':
                        if(val.length>0) params[key] = val
                        break;
                    case 'object':
                        if(val&&Object.keys(val).length>0) params[key] = val
                        break;
                    case 'undefined':
                        break;
                    default:
                        params[key] = val
                        break;
                }

            }
            return Object.keys(params).length==0?false:true
        },
        onSearch(type){
            let api_name = 'easy'
            if(type == 'easy'){
                if(this.query.search_text==""||this.query.search_text==null||this.query.search_text==undefined){
                    return false
                }
            }else{
                this.query.is_global_search = false
                api_name = 'complex'
                if(!this.checkQuery(this.query)){
                    return false
                }
            }
            this.is_loading=true             
            this.$store.dispatch('http/post',{
                api:`shops/search/${api_name}`,
                json:this.query
            })
            .then((data)=>{
                if(data.status){
                    setTimeout(()=>{
                        this.is_loading=false
                        if(data.result.length>0){
                            this.$emit('emitSearch',data.result);
                            this.$store.commit('search/updateQuery',{type:"shop",query:{...this.query}});
                            if(this.searchPanelOpen){
                                this.searchPanelOpen=false
                            }
                        }else{
                            this.$store.commit('snackBar',{show:true,message:"找不到相符結果",icon:"error",color:""});
                        }
                        
                    },1500);
                    
                }else{
                    this.is_loading=false

                }
            });
            
        },
        onToggle(){
            this.toggle=!this.toggle
            if(this.search_open!=null||this.search_open!=undefined){
                this.$emit('emitHandle',this.toggle);
            }
        },
         onClear(){
            this.query.is_global_search = true
            this.query.search_text = ""
            this.$refs.form1.reset()
            this.$store.commit('search/clearQuery',{type:"shop"});
            this.$emit('emitCleanSearch');

        },
    }
}
</script>
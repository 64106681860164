<template>
<v-app>  
  <GuideDialog :page="$store.state.ui.uc_mode==true?'shop_list':'mallshop_list'"></GuideDialog>
  <Search v-if="$store.state.ui.uc_mode"
  v-click-outside="onClickOutsideSearch"
  :search_open="ui.search_open" 
  @emitHandle="onSearchToggle" 
  @emitSearch="onSearchResult" 
  @emitCleanSearch="onCleanSearch">
  </Search>
  <v-dialog
    v-model="ui.progress_open"
    fullscreen
    hide-overlay
    transition="dialog-top-transition"
  
  >
    <v-fab-transition>
      <v-btn fab text small dark
        absolute right class="ml-2 mt-2"
        @click="ui.progress_open=false"
      >        
      <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-fab-transition>
   
    <ShopProgress v-if="ui.progress_open"/>
  </v-dialog>
  <v-main> 
    <div class="d-flex justify-center">
      <!-- 上排 -->
      
      <v-fab-transition v-if="!ui.search_open">
        <v-btn fab small text color="info"
          absolute right class="mr-12 mt-2"
          @click="$store.commit('guide/show')"
        >
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-fab-transition v-if="!ui.search_open">
        <v-btn fab small color="secondary"
          absolute right class="ml-2 mt-2"
          @click="ui.progress_open=true"
        >
        訪查<br/>進度
        </v-btn>
      </v-fab-transition>
      <!-- 左下排 -->
      <v-btn v-if="!$store.state.ui.list_mode"
        fab small absolute left bottom 
        class="mb-16" color="" style="bottom:10px"
        @click="nextPage()"
      >
        載入<br/>更多
      </v-btn>
      <v-speed-dial v-else-if="$store.state.ui.uc_mode==true" bottom left absolute small
        v-model="sort.is_open"  class="mb-16"
        direction="top"
      >
        <template v-slot:activator>
          <v-btn small fab v-model="sort.is_open">
            <v-icon v-if="sort.is_open" color="accent">mdi-close</v-icon>
            <v-icon v-else color="accent">mdi-filter-menu</v-icon>
          </v-btn>
        </template>
        <v-btn fab small @click="sort.by='updated_time'" :color="sort.by=='updated_time'?'primary':''">更新<br/>日期</v-btn>
        <v-btn fab small @click="sort.by='full_address'" :color="sort.by=='full_address'?'primary':''">依照<br/>地址</v-btn>
        
      </v-speed-dial>
      <v-btn fab small absolute left bottom 
        class="mb-10" color="accent"
        @click="$router.push({path:'/profile'})"
      >
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <!-- 右下排 -->
      <v-btn fab small absolute right bottom class="mb-10" color="accent"
        @click="$store.commit('ui/switchUCMode')"
        >{{$store.state.ui.uc_mode?"百貨":"一般"}}<br/>UC
      </v-btn> 
      <v-btn fab small 
        @click="$store.state.ui.uc_mode?$router.push({path:'/shopadd'}):$router.push({path:'/mallshopadd'})"
        style="bottom:10px"
        absolute right bottom class="mb-16" color="primary"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn> 
      
      <v-badge  v-if="gps_failed" style="z-index: 1;right: 17px;position: absolute;bottom: 130px;"
          offset-x="13" offset-y="18"
          color="red"  
          overlap absolute right bottom  
          icon="mdi-close"
      >
          <v-btn fab small @click='$store.commit("snackBar",{show:true,message:"無法存取當前位置",icon:"error",color:""});'>
              <v-icon>mdi-target</v-icon>
          </v-btn>
      </v-badge> 
      <v-btn fab small  v-else 
        @click="searchMyLocation()"
        style="bottom:60px"
        absolute right bottom class="mb-16" color=" "
      >
        <v-icon  color="accent">mdi-target</v-icon>
      </v-btn> 
      <v-btn-toggle v-if="$store.state.ui.uc_mode==true" v-model="ui.layout_toggle" mandatory 
        style="position:absolute;bottom:16px ;z-index:2">
        <v-btn  :value="false">
          <v-badge color="primary" bottom left :content="count_geo.toString()">
            <v-icon>mdi-map-marker-radius</v-icon>
          </v-badge>
        </v-btn>
        <v-btn   :value="true">
          <v-badge color="primary" bottom :content="shops.length.toString()" >
            <v-icon>mdi-table-of-contents</v-icon>
          </v-badge>
        </v-btn>
      </v-btn-toggle>    
    </div>
    <ShopList v-if="$store.state.ui.uc_mode==true" v-show="ui.layout_toggle==1" :shops="sorted_shops" @onInfiniteScroll="nextPage()" />
      
    <MallshopList v-if="$store.state.ui.uc_mode==false" />
    <GmapMap   v-if="$store.state.ui.uc_mode==true" v-show="ui.layout_toggle==0"
    :center="mapConf.center" 
    :zoom="15" 
    :options="{
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: true
    }"    
    style="width: 100%; height: 100%; position: relative;  "
    >
    <div
        :key="i" 
        v-for="(m,i) in markers" 
    >
        <!-- <GmapInfoWindow
          :options="mapWD.infoOptions" 
          :position="mapWD.infoWindowPos" 
          :opened="mapWD.infoWinOpen" 
          @closeclick="mapWD.infoWinOpen=false"
        >
          <MapInfoCard :info="selectedMK"></MapInfoCard>
        </GmapInfoWindow> -->
        <GmapMarker 
          :position="m.position" 
          :icon ="m.icon"
          :clickable="true" 
          @click="toggleInfoWindow(m,i)"
        >
        </GmapMarker>
    </div>
        <GmapMarker 
          :position="myLocate" 
          icon ='https://maps.google.com/mapfiles/kml/pal3/icon28.png'
          :clickable="false" 
         >
        </GmapMarker>
    </GmapMap>
     <v-bottom-sheet v-model="mapWD.infoWinOpen"  >
        <v-card class="text-center pb-2" flat tile color="grey lighten-4">
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="mapWD.infoWinOpen = false"
                > <v-icon>mdi-close</v-icon> </v-btn>
            </v-card-title>
              <MapInfoCard :info="selectedMK" :show_photo="true"></MapInfoCard>

       </v-card>
    </v-bottom-sheet>

</v-main>     
  
</v-app>
</template>
<style >
.gm-style .gm-style-iw-c{
  box-shadow:none !important;
  /* min-width:300px !important;
  min-height: 180px !important;
  padding-left: 0px !important; */
}
</style>
<script>
import {gmapApi} from 'vue2-google-maps'
import MapInfoCard from '@/components/common/MapInfoCard.vue'
import ShopList from '@/components/shop/ShopList'
import MallshopList from '@/components/shop/MallshopList'
import Search from '@/components/common/Search'
import ShopProgress from '@/components/dashboard/ShopProgress'
import GuideDialog from '@/components/common/GuideDialog'

import axios from 'axios'

export default {
  name: 'Gmap',
  components: {
    MapInfoCard,ShopList,MallshopList,Search,ShopProgress,GuideDialog
  },
  mounted(){
    if(this.$store.state.search.shop!=null){
      this.detectGeo().then(()=>{
        this.getShops()
      })
    }else{
      this.searchMyLocation()
    }
  },
  data() {
    return {
      shops:[],
      page:1,
      gps_failed:false,
      sort:{
        is_open:false,
        by:'updated_time'
      },
      ui:{
        search_open: false,
        progress_open:false,
        layout_toggle:0,
      }, 
      mapConf:{
        center: {
          lat: 25.0330,
          lng:  121.5654
        },
      },
      mapWD:{
        infoWindowPos: null,
        infoWinOpen: false,
        infoOptions: {
          disableDefaultUi:true,
          rotateControl: false,
          mapTypeControl: false,
          scaleControl: false,
          //content: '',
          //optional: offset infowindow so it visually sits nicely on top of our marker
          pixelOffset: {
              width: 0,
              height: -35
          }
        },
      },
      
      selectedMK:{},
      selectedMKidx:0,
      markers: [],
      curr_geo:{
        lat:0,
        lng:0,
        city:"",
        distrcit:""
      },
      geo_update_queue:[],
      geo_update_switch: false
     }
  },
  computed: {
    google: gmapApi,
    myLocate(){
      return {lat:this.curr_geo.lat,lng:this.curr_geo.lng}
    },
    no_geo_shops(){
      return this.shops.filter(x => x.lng=="")
    },
    count_geo(){
      return this.shops.length - this.no_geo_shops.length
    },
    sorted_shops(){
      if(this.sort.by=='updated_time'){
        return this.shops.map(x=>x).sort((a,b) => {
          return this.$moment(b.updated_time).valueOf()-this.$moment(a.updated_time).valueOf()}  )
      }else{
        return this.shops.map(x=>x).sort((a,b) => a.full_address - b.full_address )

      }
    }
  },
  
  watch:{
    geo_update_switch:{
        handler(newVal,oldVal){
            this.$store.dispatch("http/put",{
              api:`shops/geo/list`,
              json:{shops:this.geo_update_queue}
            }).then((data)=>{
              if(data.status){
                this.no_geo_shops.forEach((item,idx) => {
                    let find_shop = this.geo_update_queue.find(x => x.shop_id == item.shop_id)
                    item.lat = find_shop.lat
                    item.lng = find_shop.lng
                })
              }
            });
        }
    },
    no_geo_shops:{
      handler(newVal,oldVal){
        if(newVal){
          const length  = newVal.length
           newVal.forEach((item,idx) => {
              let addressObj = {
                address_line_1: item.address,
                city:item.city,
                zip_code:item.zip_code,  
                country:'Taiwan'
              }
              this.$geocoder.send(addressObj, response => { 
                 
                  if(response.status=='OK'){
                      let lat=response.results[0].geometry.location.lat.toString()
                      let lng=response.results[0].geometry.location.lng.toString()
                      this.geo_update_queue.push({
                        lat:lat,
                        lng:lng,
                        shop_id:item.shop_id
                      })
                      // item.lat = lat
                      // item.lng = lng
                      
                   //     this.$store.dispatch("http/put",{
                  //       api:`shops/geo_list`,
                  //       json:{lat:lat,lng:lng}
                  //     }); 
                  }
                  if(idx == length -1){
                      this.geo_update_switch = !this.geo_update_switch
                  }
              })
          });
        }
      }
    },
    shops:{
       handler(newVal,oldVal){
          if(newVal&&newVal.length>0){
            let new_markers = []
            for(let idx in newVal){
              let item = newVal[idx]
              new_markers.push({
                position:{
                  lat: parseFloat( item.lat),
                  lng: parseFloat( item.lng)
                },
                icon:{url:item.year?'https://maps.google.com/mapfiles/ms/icons/blue-dot.png':'https://maps.google.com/mapfiles/ms/icons/red-dot.png'},
                data:item
              })
            }
            let first_lat = new_markers[new_markers.length-1].position.lat
            let first_lng = new_markers[new_markers.length-1].position.lng
            if(this.curr_geo.lng==0 && first_lat != ""){
              this.setMapCenter({
                lat: parseFloat( first_lat),
                lng: parseFloat( first_lng)
              })
            }
            this.selectedMK = new_markers[new_markers.length-1]
            this.markers = new_markers
            
         }
          
        },
        immediate:true
    },
    "ui.layout_toggle":{
      handler(newVal,oldVal){
        if(oldVal==undefined){
          this.ui.layout_toggle = this.$store.state.ui.list_mode
        }else{
          this.$store.commit('ui/switchListMode',newVal)

        }
      },
      immediate:true
    }
  },
  methods: {
    
    setMapCenter({lng,lat}){
      this.mapConf.center.lat = 0
      this.mapConf.center.lng = 0
      setTimeout(()=>{
        this.mapConf.center.lat = lat
        this.mapConf.center.lng = lng
      },800)
    },
    searchMyLocation(){
      this.$store.commit('loadingBox',{show:true});
      this.detectGeo().then(async (geo)=>{
        if(geo){
          let locate = await this.geoToDistrict(geo.lng,geo.lat) 
          if(locate){
            this.setMapCenter({lng:geo.lng,lat:geo.lat})
            this.$store.commit('search/clearQuery',{type:"shop"});
            this.$store.commit('search/updateQuery',{type:"shop",query:{...locate}});
          }
        }
        
      }).then(()=>{
        this.clean()
        this.$store.commit('loadingBox',{show:false});
        this.getShops();
      })
    },
    nextPage(){
      this.page+=1
      let append = true
      this.getShops(append)
      if(this.ui.layout_toggle==0){
        this.$store.commit('loadingBox',{show:true});
        setTimeout(()=>{
          this.$store.commit('loadingBox',{show:false});
        },1000)
      }
    },
    getShops(is_append=false){
      if(this.$store.state.search.shop!=null){
        this.$store.dispatch("search/getShopSearchResult",{type:"complex",page:this.page})
        .then((data)=>{
          this.shops=is_append?[...this.shops,...data]:[...data]
        }); 
      }else{
        this.$store.dispatch("http/get",{api:"shops",json:{page:this.page}})
        .then((data)=>{
          this.shops=is_append?[...this.shops,...data.result]:[...data.result]

        }); 
      }
    },
    onSearchResult(data){
      this.page=1
      this.shops = data
      if(data[0].lat != ""){
        this.setMapCenter({
          lat:parseFloat( data[0].lat),
          lng:parseFloat( data[0].lng)
        })
      }
      
    },
    onClickOutsideSearch(){
      if(this.ui.search_open){
        this.ui.search_open=false
      }
    },
      onSearchToggle(flag){
        this.ui.search_open=flag
      },
      geoToDistrict(lng,lat){
        this.curr_geo.city=""
        this.curr_geo.district=""
        return new Promise((resolve, reject) => {
          axios.get(`https://api.nlsc.gov.tw/other/TownVillagePointQuery/${lng}/${lat}/`)
          .then( ({data}) =>{
            this.curr_geo.city=data.city
            this.curr_geo.district=data.district
            resolve({
              "city":data.ctyName,
              "district":data.townName
            });

          })
          .catch( (error)=> {
              console.log("geoToDistrict error",error)
              this.$store.commit('dialogBox',{dialog:true,option:{
                btnConfirmText:'我知道了',
                message:'行政區查詢 API異常！請確保網路連線或稍後再試',
                btnCancelShow:false,
                icon:'error',
                btnConfirmEvent:()=>{
                }
              }});
              resolve(false);
          });
        })
      },
      detectGeo(){
        return new Promise((resolve, reject) => {
          this.gps_failed = true
          if(!("geolocation" in navigator)) {
              console.log('Geolocation is not available.');
              this.$store.commit('dialogBox',{dialog:true,option:{
                btnConfirmText:'我知道了',
                message:'無法連接GPS！請確保網路連線正常且允許GPS存取，並重新載入頁面。',
                btnCancelShow:false,
                icon:'error',
                btnConfirmEvent:()=>{
                }
              }});
              resolve(false);
          } 
          // get position
          this.curr_geo.lat=0
          this.curr_geo.lng=0
          navigator.geolocation.getCurrentPosition(pos => {
            this.curr_geo.lat=pos.coords.latitude
            this.curr_geo.lng=pos.coords.longitude
            
            this.gps_failed = false
            resolve({
              lat:pos.coords.latitude,
              lng:pos.coords.longitude
            })
            
          }, err => {
            console.log("geo api err",err.message)
            this.$store.commit('dialogBox',{dialog:true,option:{
              btnConfirmText:'我知道了',
              message:'GPS API異常！請確保網路連線正常且允許GPS存取，並重新載入頁面。',
              btnCancelShow:false,
              icon:'error',
              btnConfirmEvent:()=>{
              }
            }});
            resolve(false);
          })

        })
       
        
      },
      toggleInfoWindow: function(marker, idx) {
          this.mapWD.infoWindowPos = marker.position;
          this.selectedMK = marker.data;
          //this.infoOptions.content = marker.infoText;
          //check if its the same marker that was selected if yes toggle
          if (this.selectedMKidx == idx) {
              this.mapWD.infoWinOpen = !this.mapWD.infoWinOpen;
          }
          //if different marker set infowindow to open and reset current marker index
          else {
              this.mapWD.infoWinOpen = true;
              this.selectedMKidx = idx;
          }
      },
      onCleanSearch(){
        this.clean()
        this.searchMyLocation()
      },
      clean(){
        this.page=1
        this.shops=[]
      }
    }
  }
</script>
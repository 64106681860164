<template lang="pug">
v-app
    v-main
        v-toolbar( dark src="https://picsum.photos/1920/1080?random")
            template( v-slot:img="{ props }")
                v-img( v-bind="props" gradient="to top right, rgba(219,228,42,.6), rgba(255,210,79,.6)")
            v-toolbar-title.pl-16 新增百貨店家
        v-btn( fab small color=""
            @click="$router.push({path:`/map`})"
            absolute left top class="mt-7") 
                v-icon mdi-chevron-left
        MallshopForm(v-if="shop_info!=null" v-model="shop_info" @onSave="onSave" is_edit_mode)
            template(v-slot:extra_fields)
                v-checkbox( v-model="current_status"
                    color="info"
                    :true-value="1"
                    :false-value="0")
                    template( v-slot:label)
                        div.info--text 本期是否盤點完畢
    
</template>
<style scoped>

  .v-item--active{
      color:inherit !important;
  }
  
</style>
<script type="text/javascript">
import MallshopForm from '@/components/shop/MallshopForm'
import BrandSelectorMallshop from '@/components/common/BrandSelectorMallshop'

export default {
    components:{BrandSelectorMallshop,MallshopForm},
    props:["copy"],
    created(){
        if(this.copy != undefined){
            this.shop_info = Object.assign(this.shop_info, this.copy);

        }else{
            this.checkDraft()
        }
    },
  
  data: () => ({
    valid: true,
    is_loading:false,
    sheet_brand:false,
    current_status:0,
    errMsg:'', 
    shop_info:{
        shoppingmall_id:null,
        department_name:"",
        desk_name:"",
        is_sample:0,
        is_single_brand:0,
        city:"",
        area:"",
        products:[],
        brands:[],
        note:"",
        gsnr:""

    },
    
  }),

  methods: {

    onSave () {
    
      this.errMsg="";
        this.is_loading=true
        console.log("save",this.shop_info)
        this.$store.dispatch("http/post",{
          api:"mallshops",
          json:this.shop_info
          
        }).then((data)=>{
            if(data.status){
                let new_id = data.result
                this.$store.commit('draft_mallshop/removeDraft',{action:'add',index:this.$store.state.draft_mallshop.add_draft_current});
                this.$store.commit('draft_mallshop/setCurrentDraft',{action:'add',index:null});
                    
                if(this.current_status){
                    this.$store.dispatch("http/post",{
                      api:`shopLogs/mallshop/${new_id}`
                    }).then((data)=>{
                        setTimeout(()=>{
                           this.is_loading=false
                            this.$store.commit('snackBar',{show:true,message:"新增成功！",icon:"success",color:""});
                            this.$router.push({path:`/mallshopedit/${new_id}`});

                        },2000)
                    })
                }else{
                   setTimeout(()=>{
                        this.is_loading=false
                        this.$store.commit('snackBar',{show:true,message:"新增成功！",icon:"success",color:""});
                        this.$router.push({path:`/mallshopedit/${data.result}`});

                    },2000)
                }
            }else if(data.code == 3){
                this.is_loading=false
                this.$store.commit('snackBar',{show:true,message:"新增失敗，資料已存在",icon:"error",color:""});

            }else{
                this.is_loading=false
                this.$store.commit('snackBar',{show:true,message:"儲存失敗！",icon:"error",color:""});

            }
          
        });
        
    
    },
   
    checkDraft(){
        this.is_draft=false
        if(this.$store.state.draft_mallshop.add_draft_current!=null){
           this.$store.commit('dialogBox',{dialog:true,option:{
                btnConfirmText:'好，我要復原',
                btnCancelText:'不用謝謝',
                message:`是否復原先前儲存的草稿?`,
                btnCancelShow:true,
                icon:'info',
                btnConfirmEvent:()=>{
                  this.$store.commit('loadingBox',{show:true});

                  setTimeout(()=>{
                    this.shop_info = {...this.$store.state.draft_mallshop.add_draft[this.$store.state.draft_mallshop.add_draft_current]};
                    this.$store.commit('loadingBox',{show:false});
                  },2000)
                },
                btnCancelEvent:()=>{
                    this.$store.commit('draft_mallshop/setCurrentDraft',{action:'add',index:null});

                }
            }});
        }else if(this.$store.state.draft_mallshop.add_draft.length>0){
           this.$store.commit('dialogBox',{dialog:true,option:{
                btnConfirmText:'好，我要復原',
                btnCancelText:'不用謝謝',
                message:`找到${this.$store.state.draft_mallshop.add_draft.length}筆草稿，是否復原先前儲存的草稿?`,
                btnCancelShow:true,
                icon:'info',
                btnConfirmEvent:()=>{
                  this.$store.commit('loadingBox',{show:true});

                  setTimeout(()=>{
                    this.shop_info = {...this.$store.state.draft_mallshop.add_draft[0]};
                    console.log(this.$store.state.draft_mallshop.add_draft[0],this.shop_info)
                    this.$store.commit('draft_mallshop/setCurrentDraft',{action:'add',index:0});
                    this.$store.commit('loadingBox',{show:false});
                  },2000)
                },
               btnCancelEvent:()=>{
                this.$store.commit('draft/setCurrentDraft',{action:'add',index:null});
              }
            }});
        }
      },
  }
}
</script>
<template  lang="pug">
v-app(v-bind:class="{'keyboard-on':$store.state.ui.is_keyboard_open}")  
    v-main
        v-toolbar( dark src="https://picsum.photos/1920/1080?random")
            template( v-slot:img="{ props }")
                v-img( v-bind="props" gradient="to top right, rgba(219,228,42,.6), rgba(255,210,79,.6)")
            v-toolbar-title.pl-16 編輯百貨店家
        v-btn.mt-7( fab small @click="$router.push({path:`/map`})" absolute left top) 
            v-icon mdi-chevron-left
        v-btn.mt-7( fab small @click="$store.dispatch('ui/copyMallshop',shop_info)" absolute right top) 
            v-icon mdi-content-copy
        MallshopForm(v-if="shop_info!=null" v-model="shop_info" @onSave="onSave" is_edit_mode)
            template(v-slot:header)
                div.d-flex.justify-space-between
                    div ID：{{shop_info.mallshop_code}}
                    div 上次編輯：{{shop_info.updated_name}}
                div.d-flex.justify-end {{shop_info.updated_time | moment( "YYYY年MM月DD日")}}
            template(v-slot:extra_fields)
                v-checkbox( v-model="current_status"
                    color="info"
                    :true-value="1"
                    :false-value="0")
                    template( v-slot:label)
                        div.info--text 本期是否盤點完畢
</template>
<style scoped>

</style>
<script type="text/javascript">
import MallshopForm from '@/components/shop/MallshopForm'
import BrandSelectorMallshop from '@/components/common/BrandSelectorMallshop'
export default {
    components:{BrandSelectorMallshop,MallshopForm},
    props:["mallshop_id"],
    inject:['reload'],
    created(){
        this.$store.dispatch("http/get",{
          api:`mallshops/${this.mallshop_id}`
        }).then((data)=>{
            console.log(data)
            this.shop_info = data.result
            this.checkDraft()
        });
      
        this.getShopLog()

  },
  data: () => ({
    valid: true,
    is_draft:false,
    is_loading:false,
    origin_status:0,
    current_status:0,
    errMsg:'',
    shop_info:{},
    
  }),
    computed:{
       
    },
    watch:{
      
    },
  methods: {
     
    getShopLog(){
        let today = new Date()
        this.$store.dispatch("http/get",{
            api:`shopLogs/latest/mallshop/${this.mallshop_id}`,
        }).then((data)=>{
            if(data.status&&data.result.year==today.getFullYear()){
                this.origin_status = 1
            }else{
                this.origin_status = 0
            }
            this.current_status = this.origin_status
        })
    },
    
   
    onSave (result) {
    
        this.errMsg="";
        this.is_loading=true
        this.$store.dispatch("http/put",{
          api:`mallshops/${this.mallshop_id}`,
          json: this.shop_info
          
        }).then((data)=>{
            if(data.status){
                if(this.current_status){
                    this.$store.dispatch("http/post",{
                      api:`shopLogs/mallshop/${this.shop_info.mallshop_id}`
                    }).then((data)=>{
                      this.afterSave()
                    })
                }else{
                    this.afterSave()
                }
            }else{
                this.is_loading=false
                this.$store.commit('snackBar',{show:true,message:"儲存失敗！",icon:"error",color:""});

            }
         
        });
        
      
    },
   
    checkDraft(){
        this.is_draft=false
        if(this.$store.state.draft_mallshop.edit_draft[this.mallshop_id]){
           this.$store.commit('dialogBox',{dialog:true,option:{
                btnConfirmText:'好，我要復原',
                btnCancelText:'刪除草稿',
                message:`找到此百貨尚未儲存的草稿，是否復原先前儲存的草稿?`,
                btnCancelShow:true,
                icon:'info',
                btnConfirmEvent:()=>{
                  this.$store.commit('loadingBox',{show:true});

                  setTimeout(()=>{
                    this.is_draft=true
                    this.shop_info = {...this.$store.state.draft_mallshop.edit_draft[this.mallshop_id]};
                    this.$store.commit('draft_mallshop/setCurrentDraft',{action:'edit',index:this.mallshop_id});
                    this.$store.commit('loadingBox',{show:false});
                  },2000)
                },
                btnCancelEvent:()=>{
                    this.$store.commit('draft_mallshop/removeDraft',{action:'edit',index:this.mallshop_id});
                    this.$store.commit('draft_mallshop/setCurrentDraft',{action:'edit',index:null});
                    this.$store.commit('snackBar',{show:true,message:"草稿已刪除",icon:"success",color:""});
                }
            }});
        }
      },
    afterSave(){
        setTimeout(()=>{
            this.is_loading=false
            this.$store.commit('snackBar',{show:true,message:"修改成功！",icon:"success",color:""});
            if(this.is_draft==true){
                this.$store.commit('dialogBox',{dialog:true,option:{
                    btnConfirmText:'移除草稿',
                    btnCancelText:'不要謝謝',
                    message:`是否要移除已儲存的草稿？`,
                    btnCancelShow:true,
                    icon:'info',
                    btnConfirmEvent:()=>{
                        this.$store.commit('draft_mallshop/removeDraft',{action:'edit',index:this.mallshop_id});
                        this.$store.commit('draft_mallshop/setCurrentDraft',{action:'edit',index:null});
                        this.$store.commit('snackBar',{show:true,message:"草稿已刪除",icon:"success",color:""});
                        this.reload()
                    },
                    btnCancelEvent:()=>{
                        this.reload()
                    }
                }});
            }else{
                this.reload()
            }
        },2000)
        
      },
    
  }
}
</script>
<template lang="pug">
v-app
  
    v-btn( fab small color="" absolute left top class="mt-8" @click="$router.go(-1)")
        v-icon mdi-chevron-left 
    ShopForm(v-if="shop_info!=null" v-model="shop_info" @onSave="onSave" is_edit_mode)
        template(v-slot:header)
          v-row
            v-col
                span( class="pb-0 caption text--secondary") {{shop_info.shop_code}}
                br
                span 上次編輯: {{shop_info.updated_name}} ｜ {{shop_info.updated_time| moment( "YYYY年MM月DD日")}}
            
            v-col( class="d-flex justify-end")
                v-btn( small color="info" @click="onShopClosedClick" :loading="loader_btn") 登記今日店休
            
        template(v-slot:extra_fields)
            v-checkbox( v-model="current_status"
                color="info")
                template( v-slot:label)
                    div( class="info--text") 本期是否盤點完畢
                
</template>

<style scoped>

  .v-item--active{
      color:inherit !important;
  }
  
</style>

<script>
import ShopForm from '@/components/shop/ShopForm'

  export default {
    props:["shop_id"],
    inject:['reload'],
    components:{ShopForm},
    data: () => ({
      origin_status:false,
      current_status:false,
      loader_btn:false,
      shop_info:null,
      is_draft:false,
    }),
    
    mounted(){
        this.$store.commit('loadingBox',{show:true});

        this.$store.dispatch("http/get",{
            api:`shops/${this.shop_id}`
        }).then((data)=>{
          if(data.status){
            console.log(data.result)
            this.shop_info = data.result
            this.billboard_brand_list=[...this.shop_info.t_horizontal_billboard,...this.shop_info.t_verticle_billboard]
            this.transformShopInfo()
            this.getSearchBrands(this.shop_info.shop_category_id)
          }
          this.checkDraft()
       });
       this.getShopLog()
       setTimeout(()=>{
            this.$store.commit('loadingBox',{show:false});
        },2500)
       
    },
    computed:{

    },
    watch:{

    },
    methods:{
      checkDraft(){
        this.is_draft=false
        if(this.$store.state.draft.edit_draft[this.shop_id]){
           this.$store.commit('dialogBox',{dialog:true,option:{
                btnConfirmText:'好，我要復原',
                btnCancelText:'刪除草稿',
                message:`找到此店家尚未儲存的草稿，是否復原先前儲存的草稿?`,
                btnCancelShow:true,
                icon:'info',
                btnConfirmEvent:()=>{
                  this.$store.commit('loadingBox',{show:true});

                  setTimeout(()=>{
                    this.is_draft=true
                    this.shop_info = {...this.$store.state.draft.edit_draft[this.shop_id]};
                    this.$store.commit('draft/setCurrentDraft',{action:'edit',index:this.shop_id});
                    this.$store.commit('loadingBox',{show:false});
                  },2000)
                },
                btnCancelEvent:()=>{
                    this.$store.commit('draft/removeDraft',{action:'edit',index:this.shop_id});
                    this.$store.commit('draft/setCurrentDraft',{action:'edit',index:null});
                    this.$store.commit('snackBar',{show:true,message:"草稿已刪除",icon:"success",color:""});
                }
            }});
        }
      },
      onSave(fd){
        this.$store.commit('loadingBox',{show:true});

        this.$store.dispatch("http/put",{api:`shops/${this.shop_info.shop_id}`,json:fd})
          .then((data)=>{
            if(data.status){
              //check current status to save (status=1 finished investigate)
              if(this.current_status){
                this.$store.dispatch("http/post",{
                  api:`shopLogs/shop/${this.shop_info.shop_id}`
                }).then((data)=>{
                  this.afterSave()
                })
              }else{
                this.afterSave()
              }
              
            }else{
              this.$store.commit('loadingBox',{show:false});
            }

          });
        
      },
      getShopLog(){
        let today = new Date()
        this.$store.dispatch("http/get",{
            api:`shopLogs/latest/shop/${this.shop_id}`,
        }).then((data)=>{
            if(data.status&&data.result.year==today.getFullYear()){
              this.origin_status = true
            }else{
              this.origin_status = false
            }
            this.current_status = this.origin_status
        })
      },
      transformShopInfo(){
          this.shop_info.t_verticle_billboard = this.shop_info.t_verticle_billboard.map(x=>{
              return typeof(x)=='object'?x.brand_id:x
          })
          this.shop_info.t_horizontal_billboard = this.shop_info.t_horizontal_billboard.map(x=>{
              return typeof(x)=='object'?x.brand_id:x
          })
        
      },
      afterSave(){
        this.$store.commit('loadingBox',{show:false});

        setTimeout(()=>{
          this.$store.commit('snackBar',{show:true,message:"修改成功！",icon:"success",color:""}); 
          if(this.is_draft==true){
            this.$store.commit('dialogBox',{dialog:true,option:{
                  btnConfirmText:'移除草稿',
                  btnCancelText:'不要謝謝',
                  message:`是否要移除已儲存的草稿？`,
                  btnCancelShow:true,
                  icon:'info',
                  btnConfirmEvent:()=>{
                      this.$store.commit('draft/removeDraft',{action:'edit',index:this.shop_id});
                      this.$store.commit('draft/setCurrentDraft',{action:'edit',index:null});
                      this.$store.commit('snackBar',{show:true,message:"草稿已刪除",icon:"success",color:""});
                      this.reload()
                  },
                   btnCancelEvent:()=>{
                      this.reload()
                  }
              }});
          }else{
            this.reload()
          }
          
        },2000)
        
      },
      onShopClosedClick(){
        let today = this.$moment(new Date()).format('YYYY-MM-DD')
         this.$store.commit('dialogBox',{dialog:true,option:{
            btnConfirmText:'確定',
            btnCancelText:'取消',
            message:`此動作將會新增一筆店修紀錄，無法復原，確定登記此店家今日(
            ${today})店休？`,
            btnCancelShow:true,
            icon:'info',
            btnConfirmEvent:()=>{
              this.loader_btn=true
              this.$store.dispatch("http/post",{
                api:`shopCloseLogs`,
                json:{ 
                  "type":"shop",
                  "shop_id":this.shop_id,
                  "closed_date":today
                }
              }).then((data)=>{
                if(data.status){
                  setTimeout(()=>{
                    this.$store.commit('snackBar',{show:true,message:"註記成功！",icon:"success",color:""});
                    this.loader_btn=false
                  },1000)
                }
                
              })
             
            },
        }});
        
      },


      
      getSearchBrands(shop_category_id){
        this.$store.dispatch("http/get",{
            api:`categories/${shop_category_id}/brands`,
        }).then((data)=>{
            this.search_brand_list=data.result
            if (this.billboard_brand_list.length==0){
              this.billboard_brand_list = this.search_brand_list
            }
        })
      },
     }
  }
</script>
<template>
<v-app>  
  <v-main>    
      <v-row>
        <v-col>      
          <h3>{{$store.state.options.shop_categories.find(x=>x.shop_category_id==selected_shop_category_id).name}}</h3>
        </v-col>
      </v-row>
      <v-sheet tile :height="w_heigth" class="overflow-y-auto"
        v-if="selected_shop_category_id != null"
      > 
        <v-progress-circular v-if="show_progress"
            indeterminate
            color="secondary"
        ></v-progress-circular>
        <div v-else>
          <p v-if="category_products.length==0">找不到相關產品！</p>
          <v-list v-else>
            <v-list-item-group multiple
              v-model="selected_products[selected_shop_category_id]"
            >
              <template v-for="(item, i) in category_products">
                <v-divider :key="`divider-${i}`"></v-divider>
                <v-list-item 
                  active-class="yellow lighten-5 third--text"    
                  :key="`item-${i}`" 
                  :value="item.product_id"
                >
                  <template v-slot:default="{ active  }">
                    <v-list-item-content>
                      <v-list-item-title v-text="`${item.product_name} ${item.product_id}`"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        color="secondary"
                      ></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </div>
      </v-sheet>
    <!-- </v-expand-transition> -->
    <v-btn width="200" color="secondary" class="mt-2" @click="onSubmit">確定</v-btn>
  </v-main>
</v-app>
</template>
<style>

.brand-selector .v-slide-group__content{
    overflow-x:scroll !important;
    overflow-y:hidden !important;
    -webkit-overflow-scrolling: touch !important;
}

</style>
<script type="text/javascript">
export default {
  props:["selected_shop_category_id","products"],
  data: () => ({
    selected_products:[],//{"1":["MOU","PUB"]} when user change selected_shop_category_id, it will remain previous selection
    category_products:[],
    valid: true,
    show_progress:true,
    errMsg:'',
    
  }),
  mounted(){
  },
  watch:{
   
    products:{
      handler(newValue,oldValue){
        if(newValue.length < this.selected_products[this.selected_shop_category_id].length){
          this.$set(this.selected_products, this.selected_shop_category_id, []) 
          newValue.forEach(x => {
            this.selected_products[this.selected_shop_category_id].push(x.product_id)
            
          });

        }
      },
      deep:true

    },
    "selected_shop_category_id":{
        handler(newValue,oldValue){
          if(newValue!=oldValue){
            //refresh product list
            this.show_progress = true
            this.$store.dispatch("http/get",{
                api:`categories/${this.selected_shop_category_id}/products`,
              }).then((data)=>{
                  this.show_progress = false
                  this.category_products = data.result
                  //add empty product object if not exist in the selected_products
                  if (!(newValue in this.selected_products)){
                    this.$set(this.selected_products, newValue, []) 
                  }
                  //checkbox on if db data selected
                  for(let i in this.products){
                    let p = this.products[i]
                    if(!this.selected_products[newValue].find(x=>x==p.product_id)){
                      this.selected_products[newValue].push(p.product_id)
                    }
                  }
            });
          }
          

        },
      immediate:true
    }
  },
    computed:{
          w_heigth:()=>{
              return (window.innerHeight*0.9)-170;
          }
      },
  methods: {
    
    transformData(){
      let result= this.selected_products[this.selected_shop_category_id].map(x => {
          return {
            product_id:x,
            shop_category_id:this.selected_shop_category_id
          }
        });
        return result
    },
    onSubmit () {
      this.$emit('dialogHandle',this.transformData());
    },
    clear () {
      this.$refs.form.reset()
    }
  }
}
</script>
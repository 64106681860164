<template lang="pug">
v-form( ref="form1")
    v-container.px-6.px-sm-16
        slot(name="header")
        v-select.mt-4(
            :items="shoppingmalls"
            :rules="$store.getters['validation/inputRules'](['required'])"
            v-model="parentValue.shoppingmall_id"
            item-text="name"
            item-value="shoppingmall_id"
            label="請選擇百貨商店"
            dense outlined)
        p.d-flex.justify-start.subtitle-2.mb-0 地區：{{parentValue.area}}
        v-select.mt-2(
            :items="$store.state.options.cities"
            v-model="parentValue.city"
            label="縣市"
            dense outlined
            :rules="$store.getters['validation/inputRules'](['required'])")
        v-row
            v-col( cols="8")
                v-select.mt-4(
                    :items="departments"
                    :rules="$store.getters['validation/inputRules'](['required'])"
                    v-model="parentValue.department_name"
                    item-text="department_name"
                    item-value="department_name"
                    label="選擇分館"
                    dense outlined)
            v-col( cols="4")
                v-text-field(
                    v-model="parentValue.floor"
                    label="樓層"
                    :rules="$store.getters['validation/inputRules'](['required','num_en'])"
                    required clearable)
        
        //- v-text-field(
        //-     v-model="parentValue.desk_name"
        //-     label="櫃位名稱"
        //-     :rules="$store.getters['validation/inputRules'](['required'])"
        //-     required clearable)
        v-autocomplete(
            v-model="parentValue.desk_name"
            :items="desks"
            :rules="$store.getters['validation/inputRules'](['required'])"
            item-text="name"
            item-value="name"
            dense
            label="請選擇櫃位名稱")
        p.d-flex.justify-start.subtitle-2.mb-1 是否為樣本店
        v-btn-toggle( tile dense mandatory
            color="secondary" class="d-flex justify-start mb-6"
            v-model="parentValue.is_sample")
            v-row
                v-col( cols="6")
                    v-btn( :value="1" block) 是
                v-col( cols="6")
                    v-btn(:value="0" block) 否
        v-text-field(
            v-model="parentValue.gsnr"
            label="GSNR" readonly disabled)
        p.d-flex.justify-start.subtitle-2.mb-1 是否為單一品牌
        v-btn-toggle( tile dense mandatory
            color="secondary" class="d-flex justify-start mb-6"
            v-model="parentValue.is_single_brand")
            v-row
                v-col( cols="6")
                    v-btn( :value="1" block) 是
                v-col( cols="6")
                    v-btn( :value="0" block) 否
        v-row
            v-col                        
                v-autocomplete( flat multiple hide-no-data 
                    hide-details outlined cache-items clearable
                    v-model="parentValue.products"
                    :loading="is_loading"
                    :items="product_list"
                    :search-input.sync="search_p"
                    item-text="product_id"
                    item-value="product_id"
                    item-color="third"
                    label="選擇產品PG"
                    :rules="$store.getters['validation/inputRules'](['required'])")
                
                    template( v-slot:selection="data")
                        v-chip(
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close color="secondary"
                            @click="data.select"
                            @click:close="removeProduct(data.item)") {{ data.item.product_id }}
                   
        v-row
            v-col
                v-bottom-sheet(
                    v-model="sheet_brand"
                    persistent fullscreen)
                    template( v-slot:activator="{ on, attrs }")
                        v-btn( block tile outlined v-bind="attrs" v-on="on" :disabled="product_count==0")
                            span( v-if="product_count===0") 選擇品牌 請先選擇產品
                            span( v-else) 選擇品牌 已選擇{{brand_count}}項
                            v-icon mdi-dots-horizontal
                    v-card( tile flat)
                        v-toolbar.mb-4(color="secondary" dark)
                            v-toolbar-title 選擇品牌
                            v-spacer
                        div.text-center
                            BrandSelectorMallshop(
                            :selected_shop_category_id="parentValue.shop_category_id" 
                            :selected_products="parentValue.products" 
                            :brands="parentValue.brands"
                            @dialogHandle="onBrandSave")
        v-text-field(
            v-model="parentValue.note"
            label="備註" clearable)
        slot(name="extra_fields")
        v-row
            v-col
                v-btn( block outlined color="accent" @click="onDraftSave" ) 存成草稿
            v-col
                v-btn( block @click="onSave" :loading="is_loading") 儲存
           
        
</template>
<style scoped>

  .v-item--active{
      color:inherit !important;
  }
  
</style>
<script type="text/javascript">
import BrandSelectorMallshop from '@/components/common/BrandSelectorMallshop'
export default {
    components:{BrandSelectorMallshop},
    props:{
        "value":{
            type:Object,
            default () {
                return {}
            },
         },
         "is_edit_mode":{
            type:Boolean,
            default:false
         }, 
        
    },    
    inject:['reload'],
    created(){
        this.$store.dispatch("http/get",{
          api:`shoppingmalls`
        }).then((data)=>{
            this.shoppingmalls = data.result
        });
        this.$store.dispatch("http/get",{
            api:`products`
        }).then((data)=>{
            if(data.status){
                this.product_list = data.result
            }
        });
        this.$store.dispatch("http/get",{
          api:`desks`
        }).then((data)=>{
             this.desks = data.result
        });
  },
  data: () => ({
    valid: true,
    is_draft:false,
    is_loading:false,
    origin_status:0,
    current_status:0,
    sheet_brand:false,
    errMsg:'',
    search_p:"",
    product_list:[],
    desks:[],
    shoppingmalls:[],
    departments:[]
    // shop_info:{
    //     shoppingmall_id:null,
    //     department_name:"",
    //     desk_name:"",
    //     is_sample:0,
    //     is_single_brand:0,
    //     products:[],
    //     brands:[]

    // },
   
  }),
    computed:{
        parentValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        product_count(){
            return this.parentValue.products?this.parentValue.products.length:0
        },
        brand_count(){
            return this.parentValue.brands?this.parentValue.brands.length:0
        }
    },
    watch:{
        "parentValue.shoppingmall_id":{
            handler(newValue,oldValue){
                if(newValue!=null){
                    this.$store.dispatch("http/get",{
                        api:`shoppingmalls/${newValue}/departments`
                    }).then((data)=>{
                        this.departments = data.result
                    });
                }else{
                    this.departments = []
                }
                
            },
            immediate:true
        },
        "parentValue.city":{
            handler(newVal,oldVal){
                this.parentValue.area = this.$store.state.options.districts[this.parentValue.city]["area"]
            },
        }
    },
  methods: {
    
    removeProduct (item,type) {
        const index = this.parentValue.products.indexOf(item.product_id)
        if (index >= 0) this.parentValue.products.splice(index, 1)
        this.parentValue.brands = this.parentValue.brands.filter(x=>x.product_id!=item.product_id)
        
    },
    onBrandSave(data,is_close){
        if(is_close){
          this.sheet_brand=false
        }
        this.parentValue.brands = data.brands
    },
    onSave () {
        this.errMsg="";
        if (this.$refs.form1.validate()) {
            this.$emit('onSave',this.parentValue)
        }else{
            this.$refs.form1.inputs.find(x => x.valid == false).focus()
        }
    },
    onDraftSave(){
        this.$store.commit('draft_mallshop/addDraft',{action:"edit",data:{shoppingmall_name:this.shoppingmalls.find(x=>x.shoppingmall_id=this.parentValue.shoppingmall_id).name,...this.parentValue}});
        this.$store.commit('snackBar',{show:true,message:"草稿已儲存",icon:"success",color:""});
    },
    clear () {
      this.$refs.form.reset()
    }
  }
}
</script>
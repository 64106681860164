<template lang="pug">

v-card.shop-card( flat tile elevation="5" :style="{ 'border-left-color':getColor(info.year)+' !important'}")
    v-img(  max-height="200" v-if="show_photo&&info.photos&&info.photos.length>0"
        :src="'/api/'+info.photos[0].path")
    v-card-subtitle.pt-2.pb-0.d-flex.justify-start 最後編輯者 {{info.updated_name}} | 調查員 {{info.investigator_name}} 
        v-spacer
        v-switch.mt-0( dense :loading="is_loading"
            @click.native.prevent.stop.capture="onSwitchClick(info,$event)"
            v-model="info.is_open"  
            :true-value="1" 
            :false-value="0" )
    v-card-title.headline.pt-0(v-text="info.shop_name")
    v-card-subtitle.pb-0.d-flex.justify-start( v-text="info.full_address")

    v-card-actions
        small.ml-2 {{info.updated_time | moment(  "YYYY年MM月DD日")}}
        v-spacer
        
        v-btn( fab icon x-small)
            a.btn( :href="'tel:'+info.phone")
                v-icon mdi-phone
        
        v-btn( fab icon x-small @click="$router.push({path:'/shopedit/'+info.shop_id});")
            v-icon mdi-square-edit-outline
</template>
<style scoped>
.shop-card{
    border-left:solid !important;
    border-left-width:8px !important;
}
.v-btn a{
  text-decoration: none !important;
}
</style>
<script type="text/javascript">
export default {
    props:["info","show_photo"],
    data: () => ({
        is_loading:false
    }),
    mounted(){
     },
    methods: {
        test(a,e){
            console.log("test",a,e)
        },
    getColor(year){
          return year?this.$store.state.ui.color.shop_state["done"]:this.$store.state.ui.color.shop_state["pending"]
      },
      
      onSwitchClick(item,e){
          this.$store.commit('dialogBox',{dialog:true,option:{
                title:item.is_open?"永久歇業":"開啟營業",
                message:item.is_open?`此動作將會強制「完成訪查」，你確定<strong>${item.shop_name}</strong>永久歇業?`:`此動作將會取消「完成訪查」的紀錄，你確定<strong>${item.shop_name}</strong>要開啟營業?`,
                btnCancelShow:true,
                icon:'warning',
                btnConfirmText:'確定執行',
                btnConfirmEvent:()=>{
                    item.is_open = item.is_open?0:1
                    this.is_loading=true
                    this.$store.dispatch("http/put",{
                        api:`shops/open/${item.shop_id}/${item.is_open}`
                    }).then((data)=>{
                        this.is_loading=false
                        if(data.status){
                            if(item.is_open){
                                this.$store.commit('snackBar',{show:true,message:"已將店家狀態改為「正常營業」",icon:"success",color:""});
                            }else{
                                this.$store.commit('snackBar',{show:true,message:"已將店家狀態改為「永久停業」",icon:"success",color:""});
                            }
                        }else{
                                this.$store.commit('snackBar',{show:true,message:"更新失敗！",icon:"error",color:""});

                        }
                    })
                }
            }});
        
        
      }
    }
}
</script>
<template lang="pug">
    v-form( ref="form1")
        ImgGallery( @onUploadChange="onUploadChange" @onDelete="onDeletePhoto" :defaultPhotos="parentValue.photos")
        v-card( tile elevation="1" color="grey lighten-3")
            v-card-title( class="pb-0") 
                slot(name="header")
                 
            v-card-text(class="pb-0 px-6 px-sm-16")
                v-text-field(v-model="parentValue.shop_name"
                placeholder="請輸入商店名稱"
                :rules="$store.getters['validation/inputRules'](['required'])"
                required clearable)
              
        v-container( class="px-6 px-sm-16")
            v-select(class="mt-2" dense outlined
                v-model="parentValue.shop_category_id"
                :items="$store.state.options.shop_categories"
                item-text="name"
                item-value="shop_category_id"
                label="商店店型"
                :rules="$store.getters['validation/inputRules'](['required'])")
        
            p(class="d-flex justify-start subtitle-2 mb-0") 地區：{{parentValue.area}}
            v-row
                v-col( class="pb-0" cols="6")
                    v-select(
                        :items="$store.state.options.cities"
                        v-model="parentValue.city"
                        label="縣市"
                        dense outlined
                        :rules="$store.getters['validation/inputRules'](['required'])")
            
                v-col( class="pb-0" cols="6")
                    v-select(
                        :items="$store.state.options.districts[parentValue.city]['districts']"
                        :rules="$store.getters['validation/inputRules'](['required'])"
                        v-model="parentValue.district"
                        item-text="district"
                        item-value="district"
                        label="鄉鎮市區"
                        dense outlined)
                
                v-col( class="py-0")
                    v-text-field(dense
                        v-model="parentValue.address"
                        label="地址"
                        :rules="$store.getters['validation/inputRules'](['required'])"
                        required clearable)
                
                v-col( class="py-0" cols="3")
                    v-text-field( dense
                        v-model="parentValue.post_code"
                        :rules="$store.getters['validation/inputRules'](['required'])"
                        label="郵遞區號" readonly
                        required)
                
            v-row
                v-col
                    v-alert(icon="mdi-map-marker-radius" outlined type="info" border="left") 經緯度：{{parentValue.lng}} , {{parentValue.lat}} 
            v-text-field( class="pt-0" v-if="$store.getters['shopform/visualRule']('hidden1',parentValue)"
                v-model="parentValue.floor_count"
                label="樓層數" type="number"
                :rules="$store.getters['validation/inputRules'](['required','number','numMin'],-1)"
                required clearable)
            v-text-field(v-model="parentValue.phone"
                label="電話" 
                hint="02-xxxxxx、09xx-xxxxxx" persistent-hint
                :rules="$store.getters['validation/inputRules'](['required','phone2'])"
                required clearable)
            v-text-field(v-model="parentValue.email"
                label="Email" 
                :rules="$store.getters['validation/inputRules'](['email'])"
                required clearable)
            v-text-field( v-if="parentValue.shop_category_id==14"
                v-model="parentValue.t_chain_name"
                label="公司名稱" color="info"
                :rules="$store.getters['validation/inputRules'](['required'])"
                required clearable)
            v-row(v-if="$store.getters['shopform/visualRule']('billboard_related',parentValue)")
                v-col( cols="12" md="6")
                    div 直式招牌
                    v-btn-toggle( dense mandatory color="secondary" class="d-flex justify-start mb-4"
                        v-model="parentValue.t_has_billboard_v")
                        v-btn( :value="1") 有
                        v-btn( :value="0") 無
                        v-btn( :value="-1") 不適用
                    v-autocomplete(class="mt-4" flat multiple hide-no-data hide-details outlined cache-items 
                        v-if="parseInt(parentValue.t_has_billboard_v)==1" clearable
                        v-model="parentValue.t_verticle_billboard"
                        :loading="loading"
                        :items="billboard_brand_list"
                        :search-input.sync="search_v"
                        item-text="brand_name"
                        item-value="brand_id"
                        item-color="third"
                        label="直式招牌品牌"
                        :rules="$store.getters['validation/inputRules'](['required'])"
                    )
                        template( v-slot:selection="data")
                            v-chip(
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close color="secondary"
                                @click="data.select"
                                @click:close="remove(data.item,'v')"
                            ) {{ data.item.brand_name }}     
                v-col
                    div 橫式招牌
                    v-btn-toggle( dense mandatory color="secondary" class="d-flex justify-start mb-4"
                        v-model="parentValue.t_has_billboard_h")
                        v-btn(:value="1") 有
                        v-btn(:value="0") 無
                        v-btn(:value="-1") 不適用
            
                    v-autocomplete( flat multiple hide-no-data hide-details outlined cache-items 
                        v-if="parseInt(parentValue.t_has_billboard_h)==1" clearable
                        v-model="parentValue.t_horizontal_billboard"
                        :loading="loading"
                        :items="billboard_brand_list"
                        :search-input.sync="search_h"
                        item-text="brand_name"
                        item-value="brand_id"
                        item-color="third"
                        label="橫式招牌品牌"
                        :rules="$store.getters['validation/inputRules'](['required'])")
                
                        template(v-slot:selection="data")
                            v-chip(
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close color="secondary"
                                @click="data.select"
                                @click:close="remove(data.item,'h')"
                            ) {{ data.item.brand_name }}
                
            v-row
                v-col( cols="6")
                    v-text-field( class="pt-0" v-if="$store.getters['shopform/visualRule']('t_positioning_machine',parentValue)"
                        v-model="parentValue.t_positioning_machine"
                        label="定位機" placeholder="台" type="number"
                        :rules="$store.getters['validation/inputRules'](['required','number','numMin'],-1)"
                        required clearable)
                    v-text-field( class="pt-0" v-if="$store.getters['shopform/visualRule']('show_only_car',parentValue)"
                        v-model="parentValue.c_jacking_count"
                        label="頂車機(頂高機)" placeholder="台" type="number"
                        :rules="$store.getters['validation/inputRules'](['required','number','numMin'],-1)"
                        required clearable)
                v-col( cols="6")
                    v-text-field(  class="pt-0" v-if="$store.getters['shopform/visualRule']('t_lift',parentValue)"
                        v-model="parentValue.t_lift"
                        label="升降機" placeholder="台" type="number"
                        :rules="$store.getters['validation/inputRules'](['required','number','numMin'],-1)"
                        required clearable)
            v-text-field(  class="pt-0" v-if="$store.getters['shopform/visualRule']('t_tire_percentage',parentValue)"
                v-model="parentValue.t_tire_percentage"
                label="輪胎銷售%" placeholder="百分比" type="number"
                :rules="$store.getters['validation/inputRules'](['required','number','numBetween'],[0,100])"
                required clearable)
            v-select(v-if="$store.getters['shopform/visualRule']('t_tire_monthly_sold_count',parentValue)"
                :items="t_tire_monthly_sold_count"
                v-model="parentValue.t_tire_monthly_sold_count"
                item-text="option_text"
                item-value="option_value"
                label="每月銷售條數"
                dense outlined clearable
            )
            div.mb-3(v-if="$store.getters['shopform/visualRule']('show_only_car',parentValue)")
                v-text-field(  class="pt-0" 
                    v-model="parentValue.c_engineoil_percentage"
                    label="機油銷售%" placeholder="百分比" type="number"
                    :rules="$store.getters['validation/inputRules'](['required','number','numBetween'],[0,100])"
                    required clearable)
                div.mb-3 機油類型銷售％（以下三種類型總和須為100%）
                v-text-field(  class="pt-0" v-if="$store.getters['shopform/visualRule']('show_only_car',parentValue)"
                    v-model="parentValue.c_full_synthetic"
                    label="全合成機油％" placeholder="百分比" type="number"
                    :rules="$store.getters['validation/inputRules'](['required','number','numBetween'],[0,100])"
                    required clearable)
                v-text-field(  class="pt-0" v-if="$store.getters['shopform/visualRule']('show_only_car',parentValue)"
                    v-model="parentValue.c_semi_synthetic"
                    label="半合成機油％" placeholder="百分比" type="number"
                    :rules="$store.getters['validation/inputRules'](['required','number','numBetween'],[0,100])"
                    required clearable)
                v-text-field(  class="pt-0" v-if="$store.getters['shopform/visualRule']('show_only_car',parentValue)"
                    v-model="parentValue.c_mineral"
                    label="礦物油％" placeholder="百分比" type="number"
                    :rules="$store.getters['validation/inputRules'](['required','number','numBetween'],[0,100])"
                    required clearable)
            v-select(v-if="$store.getters['shopform/visualRule']('show_only_car',parentValue)"
                :items="c_montly_served_cars_list"
                v-model="parentValue.c_montly_served_cars"
                item-text="option_text"
                item-value="option_value"
                label="每月入廠服務車數"
                dense outlined clearable
            )
            p( class="d-flex justify-start subtitle-2 mb-1") 商店型態
            v-btn-toggle( tile dense mandatory
                color="secondary" class="d-flex justify-start mb-6"
                v-model="parentValue.shop_type") 
                v-row
                    v-col( cols="6")
                        v-btn(value="IND" block) IND
                    v-col( cols="6")
                        v-btn( value="ORG" block) ORG
            div(v-if="$store.getters['shopform/visualRule']('hidden1',parentValue)")
                p( class="d-flex justify-start subtitle-2 mb-1") 門市類別
                v-btn-toggle( tile dense mandatory
                    color="secondary" class="d-flex justify-start mb-6"
                    v-model="parentValue.is_headquarter") 
                    v-row
                        v-col(v-if="parentValue.shop_type!='IND'" cols="6")
                            v-btn(value="分店" block) 分店
                        v-col( cols="6" )
                            v-btn( value="總店" block) 總店   
            v-select(
                :items="malls"
                item-text="name"
                item-value="mall_id"
                v-model="parentValue.mall_id"
                label="商場名稱"
                dense outlined clearable)
            v-text-field( class="pt-0"
                v-model="parentValue.single_brand_only"
                label="單一品牌" placeholder="填寫品牌名稱"
                required clearable)
            v-text-field( v-if="$store.getters['shopform/visualRule']('hidden1',parentValue)"
                v-model="parentValue.cashier"
                label="收銀機（台）" type="number"
                :rules="$store.getters['validation/inputRules'](['required','number','numMin'],-1)"
                required clearable)
            v-row
                v-col( cols="6")
                    p( class="d-flex justify-start subtitle-2 mb-1") 店面形式
                    v-btn-toggle( mandatory color="secondary" class="d-flex justify-start"
                        v-model="parentValue.shop_form")
                        v-btn( value="店面") 店面
                        v-btn( value="櫃檯") 櫃檯
                v-col(v-if="$store.getters['shopform/visualRule']('hidden1',parentValue)")
                    p( class="d-flex justify-start subtitle-2 mb-1") 結帳櫃台
                    v-btn-toggle( mandatory color="secondary" class="d-flex justify-start"
                        v-model="parentValue.has_checkout_counter")
                        v-btn( :value="1") 有
                        v-btn( :value="0") 無
            p( class="d-flex justify-start subtitle-2 mb-1") 商店位置
            v-btn-toggle( color="secondary" mandatory class="d-flex justify-start mb-6"
                v-model="parentValue.shop_position" )
                v-btn( value="街上") 街上
                v-btn( value="賣場/大樓") 賣場/大樓
                v-btn( value="地下街") 地下街 
            
            v-row
                v-col( cols="6")
                    v-text-field(  class="pt-0"
                        v-model="parentValue.area_size"
                        label="商店面積" placeholder="平方公尺" type="number"
                        :rules="$store.getters['validation/inputRules'](['required','numMin'],0)"
                        required clearable)
                
                v-col( cols="6")
                    v-text-field(  class="pt-0"
                        v-model="parentValue.service_percentage"
                        label="服務維修%" placeholder="百分比" type="number"
                        :rules="$store.getters['validation/inputRules'](['required','number','numBetween'],[0,100])"
                        required clearable)
            v-row
                v-col( cols="6")
                    v-text-field( class="pt-0"
                        v-model="parentValue.b2c_percentage"
                        label="B2C%" placeholder="百分比" type="number"
                        :rules="$store.getters['validation/inputRules'](['required','number','numBetween'],[0,100])"
                        required clearable)
                    
                v-col( cols="6")
                    v-text-field(  class="pt-0"
                        v-model="parentValue.b2b_percentage" readonly
                        label="B2B%" placeholder="百分比" type="number"
                        :rules="$store.getters['validation/inputRules'](['required','number','numBetween'],[0,100])"
                        required)
            v-row
                v-col( cols="6")
                    v-text-field(  class="pt-0" v-if="$store.getters['shopform/visualRule']('hidden1',parentValue)"
                        v-model="parentValue.branch_store_count"
                        label="分店數" placeholder="0" type="number"
                        :rules="$store.getters['validation/inputRules'](['required','number','numMinEq'],0)"
                        required clearable)
                v-col( cols="6")
                    v-text-field(  class="pt-0" v-if="$store.getters['shopform/visualRule']('hidden1',parentValue)"
                        v-model="parentValue.employee_count" 
                        label="員工數" placeholder="必須大於0" type="number"
                        :rules="$store.getters['validation/inputRules'](['required','number','numMin'],0)"
                        required)
            v-row
                v-col( cols="12")
                    v-text-field(  class="pt-0" v-if="$store.getters['shopform/visualRule']('hidden1',parentValue)"
                        v-model="parentValue.secondhand_percentage"
                        label="二手貨比例%" placeholder="百分比" type="number"
                        :rules="$store.getters['validation/inputRules'](['required','number','numBetween'],[0,100])"
                        required clearable)
            v-combobox( v-if="$store.getters['shopform/visualRule']('hidden1',parentValue)"
                v-model="parentValue.inventory_source"
                :items="inventory_source"
                item-text="option_text"
                item-value="option_value"
                :menu-props="{ maxHeight: '200' }"
                :rules="$store.getters['validation/inputRules'](['required'])"
                label="庫存來源（若為其他，可自行輸入）"
                placeholder="選擇符合條件或自行輸入"
                multiple chips deletable-chips clearable
                hint="可複選" :return-object="false"
                persistent-hint)
            v-select( v-if="$store.getters['shopform/visualRule']('hidden1',parentValue)"
                    :items="opening_period"
                    :rules="$store.getters['validation/inputRules'](['required'])"
                    v-model="parentValue.opening_period"
                    item-text="option_text"
                    item-value="option_value"
                    label="開業年期")
            p(class="d-flex justify-start subtitle-2 mb-1") 商店規模：{{parentValue.shop_scale}}
            v-select(
                v-model="parentValue.shop_scale_condition"
                :items="shop_scale_condition"
                item-text="option_text"
                item-value="option_value"
                :menu-props="{ maxHeight: '200' }"
                :rules="$store.getters['validation/inputRules'](['required'])"
                label="商店規模條件"
                placeholder="選擇符合條件"
                multiple 
                hint="可複選"
                persistent-hint
            )
                template( v-slot:selection="{ item, index }")
                    v-chip(v-if="index <=2" color="secondary")
                        span {{ item.option_text }}
                    span(v-if="index ===3" class="grey--text caption") (+{{ parentValue.shop_scale_condition.length - 3 }} 項)
            
            v-text-field(
                v-model="parentValue.note"
                label="備註" clearable)
            v-text-field(
                v-model="parentValue.gsnr"
                label="GSNR"
                required readonly)
            p( class="d-flex justify-start subtitle-1 mb-1") 您是否同意GfK分享該資訊于第三方？
            v-btn-toggle(mandatory dense color="secondary" class="d-flex justify-start"
                v-model="parentValue.is_agree_sharedata")
                v-btn( value="是") 是
                v-btn( value="否") 否
                v-btn( value="不確定") 不確定
            
            v-select.mt-4(v-if="$store.getters['shopform/visualRule']('show_only_car',parentValue)"
                :items="c_monthly_forecast_list"
                v-model="parentValue.c_monthly_forecast"
                item-text="option_text"
                item-value="option_value"
                label="估算銷售預測"
                dense outlined clearable
            )
            v-text-field(v-else color="red" 
                v-model="parentValue.sale_predict"
                label="平均每月銷售額" readonly
                required)
            v-row(v-if="parentValue.attachments&&parentValue.attachments.length>0")
                v-col( cols="10")
                    div 附件：
                        a(@click="download(parentValue.attachments[0].file_id)") {{parentValue.attachments[0].name}} 
                            v-icon( color="accent") mdi-download
                v-col( cols="2" class="d-flex justify-end")
                    v-btn( small color="accent" outlined @click="parentValue.attachments = []") 重新上傳
            v-row( v-else )
                v-col
                    v-file-input(v-model="attachments" placeholder="上傳附件" solo)
            v-expansion-panels(flat)
                v-expansion-panel
                    v-expansion-panel-header.px-0
                        p( class="d-flex justify-start subtitle-1 mb-1") 品項比例（自動計算，點擊展開）
                    v-expansion-panel-content
                        v-row
                            v-col( cols="6")
                                v-text-field(  class="pt-0"
                                    v-model="parentValue.item_percentage_ce"
                                    label="CE %" placeholder="百分比" type="number"
                                    :rules="$store.getters['validation/inputRules'](['required','number','numBetween'],[0,100])"
                                    required readonly)
                            v-col( cols="6")
                                v-text-field(  class="pt-0"
                                    v-model="parentValue.item_percentage_sda"
                                    label="SDA %" placeholder="百分比" type="number"
                                    :rules="$store.getters['validation/inputRules'](['required','number','numBetween'],[0,100])"
                                    required readonly)
                        v-row
                            v-col( cols="6")
                                v-text-field(  class="pt-0"
                                    v-model="parentValue.item_percentage_mda"
                                    label="MDA %" placeholder="百分比" type="number"
                                    :rules="$store.getters['validation/inputRules'](['required','number','numBetween'],[0,100])"
                                    required readonly)
                            v-col( cols="6")
                                v-text-field( class="pt-0"
                                    v-model="parentValue.item_percentage_photo"
                                    label="Photo %" placeholder="百分比" type="number"
                                    :rules="$store.getters['validation/inputRules'](['required','number','numBetween'],[0,100])"
                                    required readonly)
                        v-row
                            v-col( cols="6")
                                v-text-field(  class="pt-0"
                                    v-model="parentValue.item_percentage_it"
                                    label="IT %" placeholder="百分比" type="number"
                                    :rules="$store.getters['validation/inputRules'](['required','number','numBetween'],[0,100])"
                                    required readonly)
                            v-col( cols="6")
                                v-text-field(  class="pt-0"
                                    v-model="parentValue.item_percentage_telecom"
                                    label="Telecom %" placeholder="百分比" type="number"
                                    :rules="$store.getters['validation/inputRules'](['required','number','numBetween'],[0,100])"
                                    required readonly)
                        v-row
                            v-col( cols="6")
                                v-text-field(  class="pt-0"
                                    v-model="parentValue.item_percentage_homeent"
                                    label="HomeEnt. %" placeholder="百分比" type="number"
                                    :rules="$store.getters['validation/inputRules'](['required','number','numBetween'],[0,100])"
                                    required readonly)
                            v-col( cols="6")
                                v-text-field(  class="pt-0"
                                    v-model="item_percentage_others"
                                    label="Others %" placeholder="百分比" type="number"
                                    :rules="$store.getters['validation/inputRules'](['required','number','numBetween'],[0,100])"
                                    required readonly)
            v-row
                v-col
                    v-bottom-sheet(v-model="sheet_product" persistent )
                
                        template( v-slot:activator="{ on, attrs }")
                            v-btn( block tile outlined
                                v-bind="attrs" color=""
                                v-on="on" :disabled="!parentValue.shop_category_id")
                                span( v-if="!parentValue.shop_category_id") 選擇產品 請先選擇商店店型
                                span( v-else) 選擇產品 已選擇{{product_count}}項
                                v-icon mdi-dots-horizontal
                    
                        v-card(tile flat)
                            v-toolbar( class="mb-4" color="secondary" dark)
                            
                                v-toolbar-title 選擇產品
                                v-spacer
                                //- v-btn( icon @click="sheet_product=false")
                                //-     v-icon mdi-close
                                //- -->
                            div(class="text-center")
                                ProductSelector( :selected_shop_category_id="parentValue.shop_category_id" :products="parentValue.products"  @dialogHandle="onProductSave")
                
            v-row
                v-col
                    v-bottom-sheet(v-model="sheet_brand" persistent fullscreen)
                    
                        template( v-slot:activator="{ on, attrs }")
                            v-btn(block tile outlined
                                v-bind="attrs"
                                v-on="on" :disabled="product_count==0")
                            
                                span( v-if="product_count===0") 選擇品牌 請先選擇產品
                                span( v-else) 選擇品牌 已選擇{{brand_count}}項
                                v-icon mdi-dots-horizontal
                        
                        BrandSelector(
                            :selected_shop_category_id="parentValue.shop_category_id" 
                            :selected_products="parentValue.products"
                            :brands="parentValue.brands" 
                            :additional_brand="parentValue.additional_brand"
                            @dialogHandle="onBrandSave"
                            @onDeleteProduct="onDeleteProduct")
            p.mt-4(v-if="Object.keys(parentValue.pg_price_table).length>0") 你所選的以下產品需填寫最高價格
            v-text-field.mt-0(v-for="(nouse,key) in parentValue.pg_price_table" :key="'pgt-'+key"
                v-model="parentValue.pg_price_table[key]" filled color="accent" background-color="blue-grey lighten-4"
                :label="key+'最高價'" placeholder="金額必須大於0" type="number"
                :rules="$store.getters['validation/inputRules'](['required','numMin'],0)"
                required)
            v-text-field(
                v-model="parentValue.note_product"
                label="產品備註" clearable placeholder="額外的新產品線可註記在此")
            v-select( v-if="$store.getters['shopform/visualRule']('hidden1',parentValue)"
                v-model="parentValue.invoice_type"
                :items="invoice_types"
                item-text="option_text"
                item-value="option_value"
                :menu-props="{ maxHeight: '200' }"
                :rules="$store.getters['validation/inputRules'](['required'])"
                label="發票類型"
                placeholder="選擇發票類型"
                multiple 
                hint="可複選"
                persistent-hint
            )
                template( v-slot:selection="{ item, index }")
                    v-chip(v-if="index <=2" color="secondary")
                        span {{ item.option_text }}
                    span(v-if="index ===3" class="grey--text caption") (+{{ parentValue.shop_scale_condition.length - 3 }} 項)
            
            
            v-checkbox(v-model="parentValue.has_ec" v-if="$store.getters['shopform/visualRule']('hidden1',parentValue)"
                color="info"
                :true-value="1"
                :false-value="0")
                template( v-slot:label)
                    div( class="info--text") 有無網路銷售
            div(v-if="parentValue.has_ec && $store.getters['shopform/visualRule']('hidden1',parentValue)")
                v-select( v-show="parentValue.has_ec==1"
                    v-model="parentValue.ec"
                    :items="ec"
                    :menu-props="{ maxHeight: '200' }"
                    :rules="parentValue.has_ec==1?$store.getters['validation/inputRules'](['required']):[]"
                    label="網路銷售平台"
                    placeholder="選擇符合條件"
                    multiple 
                    item-text="name"
                    item-value="name"
                    hint="可複選"
                    persistent-hint
                )
                    template(v-slot:selection="{ item }")
                        v-chip(color="secondary")
                            span {{ item.name }}
            div.mt-4(v-if="parentValue.ec.indexOf('自營官網')>=0")
                v-text-field(class="pt-0"
                    v-model="parentValue.website_url"
                    label="自營網站網址" placeholder="https://example.com" 
                    :rules="parentValue.ec.indexOf('自營官網')>=0?$store.getters['validation/inputRules'](['required']):[]"
                    required clearable)
                v-text-field.mt-2(class="pt-0"
                    v-model="parentValue.website_name"
                    label="自營網站名稱" 
                    :rules="parentValue.ec.indexOf('自營官網')>=0?$store.getters['validation/inputRules'](['required']):[]"
                    required clearable)
            v-text-field(class="mt-4" v-if="$store.getters['shopform/visualRule']('hidden1',parentValue)"
                v-model="parentValue.ec_percentage"
                label="網路銷售佔比" placeholder="百分比" type="number"
                :rules="$store.getters['validation/inputRules'](['required','number','numBetween'],[0,100])"
                required clearable)
            slot(name="extra_fields")
            v-row
                v-col
                    v-btn( block outlined color="accent" @click="onDraftSave") 存成草稿
                
                v-col
                    v-btn( block @click="onSave" :loading="loader_btn") 儲存
    
</template>
<style scoped>

  .v-item--active{
      color:inherit !important;
  }
  
</style>
<script>
import { debounce } from "debounce";
import BrandSelector from '@/components/common/BrandSelector'
import ProductSelector from '@/components/common/ProductSelector'
import ImgGallery from '@/components/common/ImgGallery'

export default {
    components:{BrandSelector,ProductSelector,ImgGallery},
    props:{
        "value":{
            type:Object,
            default () {
                return {}
            },
         }, 
        "is_edit_mode":{
            type:Boolean,
            default:false
         }, 
        
    },
    data () {
        return {
            loading: false,
            loader_btn:false,
            billboard_brand_list: [],
            search_v: null,
            search_h: null,
            search_brand_list:[],
            malls:[],
            ec:[],
            shop_scale_condition:[],
            t_tire_monthly_sold_count:[],
            inventory_source:[],
            opening_period:[],
            invoice_types:[],
            pg_prices:[],
            sheet_product:false,
            sheet_brand:false,
            uploads:[],
            attachments:null,
            c_montly_served_cars_list:[],
            c_monthly_forecast_list:[],
        }
    },
    computed: {
        
        parentValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        full_address(){
            return this.parentValue.city+this.parentValue.district+this.parentValue.address
        },
        item_percentage_others(){
            let count = parseInt(this.parentValue.item_percentage_ce)
            +parseInt(this.parentValue.item_percentage_sda)
            +parseInt(this.parentValue.item_percentage_mda)
            +parseInt(this.parentValue.item_percentage_photo)
            +parseInt(this.parentValue.item_percentage_it)
            +parseInt(this.parentValue.item_percentage_telecom)
            +parseInt(this.parentValue.item_percentage_homeent)

            return count>=100?0:100 - count
        },
        sale_predict_text(){
            
            return `${this.parentValue.city}/${this.parentValue.shop_category_id}/${this.parentValue.shop_scale}`
        },
        product_count(){

            return this.parentValue.products.length
        },
        brand_count(){

            let count1 = this.parentValue.additional_brand?this.parentValue.additional_brand.length:0
            let count2 = this.parentValue.brands?this.parentValue.brands.length:0

            return count1+count2
        },
        w_heigth(){
            return screen.height;
        }
    },
    
    mounted(){
        this.getOptionsData()
        this.$store.dispatch("options/getShopCategories")
        this.$store.dispatch("http/get",{
            api:"malls"
            }).then((data)=>{
            this.malls = data.result
            });
        this.$store.dispatch("http/get",{
            api:"ec"
        }).then((data)=>{
            this.ec = data.result
        });
        
    },
    watch:{
        "parentValue.shop_type":{
            handler(newVal,oldVal){
                if(!this.is_edit_mode && newVal == 'ORG'){
                    this.parentValue.is_headquarter = '分店'
                }
            }
        },
        "parentValue.shop_scale_condition":{
            handler(newVal,oldVal){
                if(newVal.length<=1){
                    this.parentValue.shop_scale = 'XS'
                }else if(newVal.length==2){
                    this.parentValue.shop_scale = 'S'
                }else if(newVal.length<=4){
                    this.parentValue.shop_scale = 'M'
                }else if(newVal.length<=6){
                    this.parentValue.shop_scale = 'L'
                }else if(newVal.length>6){
                    this.parentValue.shop_scale = 'XL'
                }
            },
        },
        "parentValue.t_has_billboard_h":{
            handler(newVal,oldVal){
                if(parseInt(newVal)!=1){
                    this.parentValue.t_horizontal_billboard = []
                }
            },
        },
        "parentValue.t_has_billboard_v":{
            handler(newVal,oldVal){
                if(parseInt(newVal)!=1){
                    this.parentValue.t_verticle_billboard = []
                }
            },
        },
        "parentValue.has_ec":{
            handler(newVal,oldVal){
                if(parseInt(newVal)!=1){
                    this.parentValue.ec = []
                }
            },
        },
        sale_predict_text:{
            handler(newVal,oldVal){
                if(this.parentValue.shop_category_id!=null&&this.parentValue.shop_scale!=""){
                    this.$store.dispatch("http/get",{
                    api:`salePredict/${this.parentValue.city}/${this.parentValue.shop_category_id}/${this.parentValue.shop_scale}`
                    }).then((data)=>{
                    if(data.status&&data.result){
                        
                        this.parentValue.sale_predict = data.result.amount
                    }else{
                        this.parentValue.sale_predict = ""
                    }
                    });
                }
            
            },
            // immediate:true
            
        },
        "parentValue.t_verticle_billboard"(newVal,oldVal){
            if(newVal.length!=0&&newVal!=oldVal){
                this.search_v=""
            }
        },
        "parentValue.t_horizontal_billboard"(newVal,oldVal){
            if(newVal.length!=0&&newVal!=oldVal){
                this.search_h=""
            }
        },
        search_v (newVal,oldVal) {
            newVal && newVal !== this.parentValue.t_verticle_billboard && this.querySelections(newVal,"v")
        },
        search_h (newVal,oldVal) {
            newVal && newVal !== this.parentValue.t_horizontal_billboard && this.querySelections(newVal,"h")
        },
        
        "parentValue.shop_category_id":{
            handler(newVal,oldVal){
                if(newVal!=oldVal){
                    if(oldVal!=null){
                        this.parentValue.products=[]
                        this.parentValue.brands=[]
                        this.parentValue.additional_brand=[]
                    }
                    if(this.parentValue.channel_id == null || this.$store.state.options.shop_categories.length>0){
                        this.parentValue.channel_id = this.$store.state.options.shop_categories.find(x=>x.shop_category_id == newVal).channel_id
                    }
                    this.getSearchBrands(newVal)
                }
            },
            immediate:true
        },
        
        "parentValue.b2c_percentage":{
            handler(newVal,oldVal){
                this.parentValue.b2b_percentage = 100-this.parentValue.b2c_percentage
            },
            immediate:true
        },
        "parentValue.district":{
            handler(newVal,oldVal){
                let districts_array = this.$store.state.options.districts[this.parentValue.city]["districts"]
                let target = districts_array.find(x=>x.district==newVal)
                this.parentValue.post_code = target?target.post_code:null

            },
            immediate:true
        },
        "parentValue.city":{
            handler(newVal,oldVal){
                this.parentValue.area = this.$store.state.options.districts[this.parentValue.city]["area"]
            },
            immediate:true
        },
        full_address:{
            handler(newVal,oldVal){
                this.onDebounce()
            
            },
        },
        "parentValue.products":{
            handler(newVal,oldVal){
                this.refreshPGPriceTable(newVal)
                this.refreshPGPercentage(newVal)

            },
            immediate:true
        },
        "parentValue.pg_price_table":{
            handler(newVal,oldVal){
                if(newVal == null){
                    this.parentValue.pg_price_table = {}
                }
            },
            immediate:true
        }

        
      
    },
    methods:{
        visibilityCondition(col_name){
            return true
        },
        getOptionsData(){ 
            this.$store.dispatch("http/get",{
                api:`options/shop_scale_condition,inventory_source,opening_period,invoice_type,pg_price,t_tire_monthly_sold_count,c_montly_served_cars,c_monthly_forecast`
            }).then((data)=>{
                if(data.status){
                    this.shop_scale_condition = data.result.shop_scale_condition
                    this.inventory_source = data.result.inventory_source
                    this.opening_period = data.result.opening_period
                    this.invoice_types = data.result.invoice_type
                    this.pg_prices = data.result.pg_price
                    this.t_tire_monthly_sold_count = data.result.t_tire_monthly_sold_count
                    this.c_montly_served_cars_list = data.result.c_montly_served_cars
                    this.c_monthly_forecast_list = data.result.c_monthly_forecast
                }
            })
           
        },
      onUploadChange(data){
        this.uploads = data.map((f)=>{
          f.data.geo_info = f.geo_info
          return f.data
        })
      },
      remove (item,type) {
        if(type=='v'){
          const index = this.parentValue.t_verticle_billboard.indexOf(item.brand_id)
          if (index >= 0) this.parentValue.t_verticle_billboard.splice(index, 1)
        }else{
          const index = this.parentValue.t_horizontal_billboard.indexOf(item.brand_id)
          if (index >= 0) this.parentValue.t_horizontal_billboard.splice(index, 1)
        }
        
      },
      querySelections (v,type) {
        this.loading = true
        this.billboard_brand_list = this.search_brand_list.filter(e => {
            return (e.brand_name || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
          })
        this.loading = false
        
      },
      onValidate(){
        if (this.$refs.form1.validate()) {
          if(this.parentValue.photos.length+this.uploads.length==0){
              this.$store.commit('snackBar',{show:true,message:"請上傳店家照片！",icon:"error",color:""});
              return false
          }
          if(!this.parentValue.products||this.parentValue.products.length==0){
              this.$store.commit('snackBar',{show:true,message:"尚未選擇產品！",icon:"error",color:""});
              return false
          }
          //檢查品牌數量
          for(let key in this.parentValue.products){
            let product_id = this.parentValue.products[key].product_id
            let count1 = this.parentValue.additional_brand?this.parentValue.additional_brand.filter(x=>x.product_id==product_id).length:0
            let count2 = this.parentValue.brands?this.parentValue.brands.filter(x=>x.product_id==product_id).length:0

            if(count1+count2==0){
              this.$store.commit('snackBar',{show:true,message:"還有產品尚未選擇品牌！",icon:"error",color:""});
              return false
            }
          }
          //檢查機油類型百分比總和
            if(this.parentValue.shop_category_id == 25){
                let total = parseInt(this.parentValue.c_full_synthetic)+parseInt(this.parentValue.c_semi_synthetic)+parseInt(this.parentValue.c_mineral)
                if(total!=100){
                    this.$store.commit('snackBar',{show:true,message:`機油類別總和須為100，目前為${total}`,icon:"error",color:""});
                    return false
                }
            }
          return true
        }else{
          this.$refs.form1.inputs.find(x => x.valid == false).focus()
          this.$store.commit('snackBar',{show:true,message:"請填寫必要欄位！",icon:"error",color:""});
          return false
        }
      },
      onDebounce: debounce( function() {
            let addressObj = {
              address_line_1: this.parentValue.address,
              city:this.parentValue.city,
              zip_code:this.parentValue.zip_code,  
              country:'Taiwan'
            }
            this.updateGeo(addressObj)
        }, 2000),
      updateGeo(addressObj){
        this.$geocoder.send(addressObj, response => { 
            if(response.status=='OK'){
                this.parentValue.lat=response.results[0].geometry.location.lat.toString()
                this.parentValue.lng=response.results[0].geometry.location.lng.toString()
            }else{
                this.parentValue.lat=""
                this.parentValue.lng=""
            }
        })
      },
      onSave(){
        if(!this.onValidate()){
          return false
        }
        this.loader_btn=true
        this.parentValue.item_percentage_others = this.item_percentage_others
        const fd = new FormData();
        this.uploads.forEach((file)=>{
            file.file_name=file.name
            fd.append('file',file)
        })
        if(this.attachments){
            this.attachments.file_name=this.attachments.name
            fd.append('file',this.attachments)
          }
        for(let key in this.parentValue){
            if(typeof(this.parentValue[key])=='object'){
              fd.append(key,JSON.stringify(this.parentValue[key]))

            }else{
              fd.append(key,this.parentValue[key])

            }
        }
        fd.append('uploads',JSON.stringify(this.uploads))
        this.$emit('onSave',fd)
        this.loader_btn = false
        
        
      },
     
      onCamBtnClick() {
        this.$refs.upload.$refs.input.click()
      },
      onDeleteProduct(product_id){
        let idx = this.parentValue.products.findIndex(x => x.product_id == product_id);
        this.parentValue.products.splice(idx,1);
      },
      onProductSave(data){
        this.sheet_product=false
        this.parentValue.products = data
        this.$store.dispatch("options/getProductBrands",this.parentValue.shop_category_id)
        
      }, 
      onBrandSave(data,is_close=true){
        if(is_close){
          this.sheet_brand=false
        }
        this.parentValue.brands = data.brands
        this.parentValue.additional_brand = data.additional_brand
      },  
      
      onDraftSave(){
        this.$store.commit('dialogBox',{dialog:true,option:{
          btnConfirmText:'我知道了',
          message:`注意：草稿不會保留您的「照片」與「附件」，請先確認是否將照片與附件留存於裝置中，事後編輯草稿時便能重新上傳。`,
          btnCancelShow:false,
          icon:'info',
          btnConfirmEvent:()=>{
            
              this.$store.commit('draft/addDraft',{action:"add",data:this.parentValue});
              this.$store.commit('snackBar',{show:true,message:"草稿已儲存",icon:"success",color:""});
          },
        }});
      },
        onDeletePhoto(photo){
            this.$store.commit('dialogBox',{dialog:true,option:{
                btnConfirmText:'確定刪除',
                message:'此刪除動作會立即生效，無法還原，確定要刪除此張照片？',
                btnCancelShow:true,
                icon:'warning',
                btnConfirmEvent:()=>{
                    this.$store.commit('loadingBox',{show:true});
                    this.$store.dispatch("http/delete",{
                        api:`shopFiles/${photo.file_id}`,
                    }).then((data)=>{
                        this.$store.commit('loadingBox',{show:false});
                        if(data.status){
                            this.parentValue.photos = this.parentValue.photos.filter(item => item.file_id !== photo.file_id)
                            this.$store.commit('snackBar',{show:true,message:"刪除成功！",icon:"success",color:""});
                        }else{
                        if(data.status == 6){
                            this.$store.commit('snackBar',{show:true,message:"刪除失敗！檔案可能遺失或系統權限不足",icon:"error",color:""});

                        }else{
                            this.$store.commit('snackBar',{show:true,message:"刪除失敗！",icon:"error",color:""});

                        }
                        }
                    })
                }
            }});
        
        },
        download(file_id){
            this.$store.commit('loadingBox',{show:true});
            this.$store.dispatch("http/download",{api:`shopfiles/download/attatchment/${file_id}`})
            .then((data)=>{
                setTimeout(()=>{
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(data.blob)
                    link.download = data.name
                    link.click()
                    URL.revokeObjectURL(link.href)
                    this.$store.commit('loadingBox',{show:false});

                },1000)
              
            })
        },
        getSearchBrands(shop_category_id){
            this.$store.dispatch("http/get",{
                api:`categories/${shop_category_id}/brands`,
            }).then((data)=>{
                 this.search_brand_list=data.result
                if (this.billboard_brand_list.length==0){
                this.billboard_brand_list = this.search_brand_list
                }
            })
        },
        refreshPGPriceTable(current_pg_list){
            for(let key in this.parentValue.pg_price_table){

                if(!current_pg_list.find(x => x.product_id == key)){
                    delete this.parentValue.pg_price_table[key]
                }
            }
            current_pg_list.forEach(x => {
                if(this.pg_prices.find( y => y.option_text == x.product_id) && !this.parentValue.pg_price_table[x.product_id]){
                    this.parentValue.pg_price_table[x.product_id] = 0
                }
            });
    
        },
        refreshPGPercentage(current_pg_list){
            let total = 0
            let tmp = {
                item_percentage_ce:0,
                item_percentage_sda:0,
                item_percentage_mda:0,
                item_percentage_photo:0,
                item_percentage_it:0,
                item_percentage_telecom:0,
                item_percentage_homeent:0,
                item_percentage_others:0
            }
            current_pg_list.forEach(x => {
                total += 1
                let item = this.$store.state.options.pg_percentage_table[x.product_id]
                if(item){
                    tmp[item] += 1
                }
            });
            for(let key in tmp){
                this.parentValue[key] = Math.round(tmp[key]*100 / total)
            }
        }
    }
}
</script>

<template lang="pug">
v-app
  
    v-btn( fab small color="" absolute left top class="mt-8" @click="$router.go(-1)")
        v-icon mdi-chevron-left 
    ShopForm(v-model="shop_info" @onSave="onSave")
        template(v-slot:extra_fields)
            v-checkbox( v-model="current_status"
                color="info")
                template( v-slot:label)
                    div( class="info--text") 本期是否盤點完畢
                
</template>

<script>
import ShopForm from '@/components/shop/ShopForm'

  export default {
    components:{ShopForm},
    data: () => ({
        current_status:false,
        shop_info:{

            shop_id:"",
            channel_id:null,
            shop_name:"",
            address:"",
            phone:"",
            shop_category_id:null,
            city:"台北市",
            district:null,
            post_code:null,
            shop_type:null,
            photos:[],
            attachments:null,
            mall_id:0,
            cashier:0,
            single_brand_only:"",
            shop_form:"",
            shop_position:"",
            area_size:0,
            shop_scale:"",
            shop_scale_condition:[],
            b2c_percentage:100,
            b2b_percentage:0,
            service_percentage:0,
            has_pos:0,
            note:"",
            note_product:"",
            additional_brand:[],
            gsnr:"",
            sale_predict:"",
            sale_predict_source:"",
            t_positioning_machine:0,
            t_verticle_billboard:[],
            t_horizontal_billboard:[],
            t_has_billboard_h:0,
            t_has_billboard_v:0,
            t_lift:0,
            t_chain_name:"",
            t_tire_percentage:0,
            t_tire_monthly_sold_count:[],
            has_ec:0,
            ec:[],
            ec_percentage:0,
            has_own_pos:0,
            products:[],
            brands:[],
            lat:"",
            lng:"",
            branch_store_count:0,
            employee_count:0,
            secondhand_percentage:0,
            inventory_source:[],
            opening_period:"",
            is_agree_sharedata:"是",
            item_percentage_ce:0,
            item_percentage_sda:0,
            item_percentage_mda:0,
            item_percentage_photo:0,
            item_percentage_it:0,
            item_percentage_telecom:0,
            item_percentage_homeent:0,
            item_percentage_others:0,
            invoice_type:[],
            has_checkout_counter:0,
            floor_count:0,
            is_headquarter:null,
            email:"",
            website_url:"",
            website_name:"",
            pg_price_table:{},
            c_engineoil_percentage:0,
            c_montly_served_cars:"",
            c_monthly_forecast:"",
            c_jacking_count:0,
            c_full_synthetic:0,
            c_semi_synthetic:0,
            c_mineral:0
        },
        
    }), 
    mounted(){
        this.$store.commit('loadingBox',{show:true});

        setTimeout(()=>{
            this.$store.commit('loadingBox',{show:false});
            this.checkDraft()
        },2500)
      
    },
    methods:{
        checkDraft(){
            if(this.$store.state.draft.add_draft_current!=null){
                this.$store.commit('dialogBox',{dialog:true,option:{
                    btnConfirmText:'好，我要復原',
                    btnCancelText:'不用謝謝',
                    message:`是否復原先前儲存的草稿?`,
                    btnCancelShow:true,
                    icon:'info',
                    btnConfirmEvent:()=>{
                        this.$store.commit('loadingBox',{show:true});

                        setTimeout(()=>{
                        this.shop_info = {...this.$store.state.draft.add_draft[this.$store.state.draft.add_draft_current]};
                        this.$store.commit('loadingBox',{show:false});
                        },2000)
                    },
                    btnCancelEvent:()=>{
                        this.$store.commit('draft/setCurrentDraft',{action:'add',index:null});
                    }
                }});
            }
            else if(this.$store.state.draft.add_draft.length>0){
                this.$store.commit('dialogBox',{dialog:true,option:{
                    btnConfirmText:'好，我要復原',
                    btnCancelText:'不用謝謝',
                    message:`找到${this.$store.state.draft.add_draft.length}筆草稿，是否復原先前儲存的草稿?`,
                    btnCancelShow:true,
                    icon:'info',
                    btnConfirmEvent:()=>{
                        this.$store.commit('loadingBox',{show:true});
                        this.$store.commit('draft/setCurrentDraft',{action:'add',index:0});
                        this.shop_info = {...this.$store.state.draft.add_draft[0]};
                        this.$store.commit('loadingBox',{show:false});
                    },
                    btnCancelEvent:()=>{
                        this.$store.commit('draft/setCurrentDraft',{action:'add',index:null});
                    }
                }});
            }
        },
        onSave(fd){
            this.$store.commit('loadingBox',{show:true});
            this.$store.dispatch("http/post",{api:"shops",json:fd})
            .then((data)=>{
                if(data.status){
                    let new_id = data.result
                    this.$store.commit('draft/removeDraft',{action:'add',index:this.$store.state.draft.add_draft_current});
                    this.$store.commit('draft/setCurrentDraft',{action:'add',index:null});
                    
                    if(this.current_status){
                        this.$store.dispatch("http/post",{
                            api:`shopLogs/shop/${new_id}`
                        }).then((data)=>{
                            setTimeout(()=>{
                                this.$store.commit('loadingBox',{show:false});
                                this.$store.commit('snackBar',{show:true,message:"新增成功！",icon:"success",color:""});
                                this.$router.push({path:`/shopedit/${new_id}`});

                            },2000)
                        })
                    }else{
                        setTimeout(()=>{
                            this.$store.commit('loadingBox',{show:false});
                            this.$store.commit('snackBar',{show:true,message:"新增成功！",icon:"success",color:""});
                            this.$router.push({path:`/shopedit/${data.result}`});

                        },2000)
                    }
                }else{
                    this.loader_btn=false
                }
          
        });
      }
      
    }
  }
</script>